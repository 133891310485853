import "./App.css";
import React, { useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./Files/Components/Layout";
import { NewHomePage } from "./Files/NewChanges/NewHomePage";
import { Portfolio } from "./Files/Portfolio";
import CareerDynamicComp from "./Files/Career-Components/CareerDynamicComp";
import ContactUs from "./Files/ContactUs";
import { WebDevelopment } from "./Files/WebDevelopment";
import NewAboutUs from "./Files/NewChanges/NewAboutUs";
import { AppDevelopment } from "./Files/AppDevelopment";
import NewCareerPage from "./Files/NewChanges/NewCareerPage";
import { UiUxDesign } from "./Files/UiUxDesigns";
import { ReactTechnology } from "./Files/ReactTechnology";
import { SeoPage } from "./Files/SEO";
import DevOps from "./Files/DevOps";
import ProjectManagement from "./Files/ProjectManagement";
import NewAboutUsTest from "./Files/NewChanges/NewAboutUsTest";
import { TechnologyDynamicComp } from "./Files/DynamicComponent/TechnologyDynamicComp";
import PageNotFound from "./Files/PageNotFound";
import { PortfolioDynamicComp } from "./Files/DynamicComponent/PortfolioDynamicComp";
import HeroSection from "./Files/HeroSection";
import { K12Portpoliopage } from "./Files/Components/K12Portpoliopage";
import A2portfolio from "./Files/A2portfolio";
import ApiIntegration from "./Files/NewPages/ApiIntegration";
import axios from "axios";
import IndianaWebDevelopment from "./Files/NewPages/IndianaWebDevelopment";
import Sitemap from "./Files/Sitemap";
import BlogPage from "./Files/NewPages/BlogPage";
import Blogpostpage from "./Files/NewPages/BlogPage/Blogpostpage";
import BlogCategoriesPage from "./Files/NewPages/BlogPage/BlogCategoriesPage";
import BlogAuthorPage from "./Files/NewPages/BlogPage/BlogAuthorPage";
import Ecommerce from "./Files/Ecommerce";
import SMBL from "./Files/NewPages/SMBL";
import Referral from "./Files/Referral";
import CrmDevelopment from "./Files/CrmDevelopment";
import ErpDevelopment from "./Files/ErpDevelopment";
import EcommerceService from "./Files/EcommerceService";
import WebApp from "./Files/WebApp";
import MpcReferral from "./Files/Referral-Pages/MpcReferral";
import K12Referral from "./Files/Referral-Pages/K12Referral";
import ChicagoWebDevelopment from "./Files/NewPages/LocationPages/ChicagoWebDevelopment";
import NewYorkWebDevelopment from "./Files/NewPages/LocationPages/NewYorkWebDevelopment";
import IndianaMobileDevelopment from "./Files/NewPages/LocationPages/IndianaMobileDevelopment";
import SanFransiscoWebDevelopment from "./Files/NewPages/LocationPages/SanFransiscoWebDevelopment";
import ChicagoMobileDevelopment from "./Files/NewPages/LocationPages/ChicagoMobileDevelopment";
import NewYorkMobileDevelopment from "./Files/NewPages/LocationPages/NewYorkMobileDevelopment";
import SanFranciscoMobileDevelopment from "./Files/NewPages/LocationPages/SanFranciscoMobileDevelopment";
export const PostId = createContext();
export default function App() {
  const CountryCode = sessionStorage.getItem("Country");
  useEffect(() => {
    if (CountryCode) {
      return;
    } else {
      axios
        .get("https://ipapi.co/country")
        .then((res) => {
          sessionStorage.setItem("Country", res.data.toLowerCase());
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <>
      <Router>
        <Layout>
          <Routes>
            <Route path="/">
              <Route index element={<NewHomePage />} />
              <Route path="/about-us" element={<NewAboutUs />} />
              <Route path="/portfolio" element={<Portfolio />} />
              {/* <Route index  />
                <Route path=":details" element={<PortfolioDynamicComp />} /> */}
              <Route
                path="/portfolio/development-of-an-erp-system-for-the-travel-industry"
                element={<HeroSection />}
              />
              <Route
                path="/portfolio/website-development-for-K12"
                element={<K12Portpoliopage />}
              />
              <Route
                path="/portfolio/hybrid-application-development-for-al-wajeeh"
                element={<SMBL />}
              />
              <Route
                path="/portfolio/custom-crm-development-for-address-two"
                element={<A2portfolio />}
              />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/career">
                <Route index element={<NewCareerPage />} />
                <Route path=":name" element={<CareerDynamicComp />} />
              </Route>
              <Route
                exact
                path="/web-development"
                element={<WebDevelopment />}
              />
              <Route
                exact
                path="/api-integration-services"
                element={<ApiIntegration />}
              />
              <Route
                exact
                path="/mobile-app-development"
                element={<AppDevelopment />}
              />
              <Route exact path="/uiux-design" element={<UiUxDesign />} />
              <Route
                exact
                path="/react-technology"
                element={<ReactTechnology />}
              />
              <Route path="/technology">
                <Route index path=":tech" element={<TechnologyDynamicComp />} />
              </Route>
              <Route exact path="/NewAboutUs" element={<NewAboutUsTest />} />
              <Route exact path="/seo" element={<SeoPage />} />
              <Route exact path="/devops" element={<DevOps />} />
              <Route
                exact
                path="/project-management"
                element={<ProjectManagement />}
              />
              <Route
                exact
                path="/crm-development-services"
                element={<CrmDevelopment />}
              />
              <Route
                exact
                path="/erp-development-services"
                element={<ErpDevelopment />}
              />
              {/* <Route exact path="/ecommerce-services" element={<EcommerceService />} /> */}
              <Route
                exact
                path="/web-application-development"
                element={<WebApp />}
              />
              <Route exact path="/NewAboutUs" element={<NewAboutUs />} />

              <Route
                exact
                path="/web-development-company-in-indianapolis"
                element={<IndianaWebDevelopment />}
              />
              <Route
                exact
                path="/web-development-company-in-chicago"
                element={<ChicagoWebDevelopment />}
              />
              <Route
                exact
                path="/web-development-company-in-new-york"
                element={<NewYorkWebDevelopment />}
              />
              <Route
                exact
                path="/web-development-company-in-san-francisco"
                element={<SanFransiscoWebDevelopment />}
              />
              <Route
                exact
                path="/mobile-app-development-company-in-indianapolis"
                element={<IndianaMobileDevelopment />}
              />
              <Route
                exact
                path="/mobile-app-development-company-in-chicago"
                element={<ChicagoMobileDevelopment />}
              />
              <Route
                exact
                path="/mobile-app-development-company-in-new-york"
                element={<NewYorkMobileDevelopment />}
              />
              <Route
                exact
                path="/mobile-app-development-company-in-san-francisco"
                element={<SanFranciscoMobileDevelopment />}
              />
              <Route path="/blog">
                <Route index element={<BlogPage />} />
                <Route path=":title" element={<Blogpostpage />} />
                <Route
                  path="category/:title"
                  element={<BlogCategoriesPage />}
                />
                <Route path="author/:title" element={<BlogAuthorPage />} />
              </Route>

              <Route exact path="/sitemap.html" element={<Sitemap />} />
              <Route
                exact
                path="/portfolio/ecommerce-website-development-for-my-pet-carnivore"
                element={<Ecommerce />}
              />
              <Route exact path="/referral-page" element={<Referral />} />
              <Route
                exact
                path="/referral-page-mpc"
                element={<MpcReferral />}
              />
              {/* <Route
                exact
                path="/referral-page-k12"
                element={<K12Referral />}
              /> */}
              <Route path="*" element={<PageNotFound />} />
              <Route path="/not-found" element={<PageNotFound />} />
            </Route>
          </Routes>
        </Layout>
      </Router>
    </>
  );
}
