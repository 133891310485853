import React, { useState, useReducer, useEffect, useRef } from "react";
import { formReducer, INITIAL_STATE } from "../Reducer/AboutUsReducer";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CountSetInterval from "../Components/CounterComp";
import PersonalizedComp from "../Components/PersonalizedComp";
import { ClientComp } from "../Components/ClientComp";
import { HeaderWithDynamicContent } from "../Components/HeaderWithDynamicContent";
import { BreadCrumbCard } from "../Components/BreadCrumbCard";
import HoverComponent from "../Components/HoverComponent";
import axios from "axios";
import HelmetMetaTag from "../Components/HelmetMetaTag";
import LazyLoadingImg from "../Components/LazyLoadingImg";
import LifeAtSprigStack from "../Components/LifeAtSprigStack";
export default function NewAboutUs() {
  var width = $(window).width();
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [countNumber, setCountNumber] = useState({
    Count1: 0,
    Count2: 0,
    Count3: 0,
    Count4: 0,
  });
  const [teamPlayersData, setTeamPlayersData] = useState([]);
  const [teamPlayersData2, setTeamPlayersData2] = useState([]);
  useEffect(() => {
    axios
      .get("/jsonFiles/EmployeeDetailsMobile.json")
      .then((res) => {
        setTeamPlayersData2(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("/jsonFiles/EmployeeDetails.json")
      .then((res) => {
        setTeamPlayersData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    $("#AS_Box1").click();
    window.addEventListener("scroll", CounterFun);
    return () => {
      window.removeEventListener("scroll", CounterFun);
    };
  }, []);

  const CounterFun = () => {
    const scrollTop = window.scrollY;
    if (scrollTop >= 500) {
      setCountNumber({
        ...countNumber,
        Count1: 100,
        Count2: 50,
        Count3: 20,
        Count4: 8,
      });
    }
  };

  var transperancyAndEthicsConent = [];
  var clientCentricApproch = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4 px-md-4"
      Image="./images/clientHandShake.svg"
      ImageClass="aboutUsIndImage SetImage"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Client-centric Approach"
      Heading4={true}
      IsSetClass={true}
      borClass="border10 bgF7F7F7 hover-hero-card-aboutus mb-3 px-4 p-lg-4 "
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody pb-4 textalignleft"
      Body="The vision behind each client requirement is regarded as the most important aspect. Considering that, our approach is always focused on transparency and is result-oriented."
    />
  );
  transperancyAndEthicsConent.push(clientCentricApproch);

  var serviceDeliveredInTime = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4 px-md-4"
      borClass="border10 bgF7F7F7 hover-hero-card-aboutus mb-3 px-4 p-lg-4 "
      Image="./images/serviceDeliveredInTime.svg"
      ImageClass="aboutUsIndImage SetImage"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Realistic Timelines"
      Heading4={true}
      IsSetClass={true}
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody pb-4 textalignleft"
      Body="It is even more essential to stick to deadlines while working in the technology sector. We constantly consider the timeline and aspire to deliver projects to our clients on time."
    />
  );
  transperancyAndEthicsConent.push(serviceDeliveredInTime);

  var effectiveCollaboration = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4 px-md-4"
      borClass="border10 bgF7F7F7 hover-hero-card-aboutus mb-3 px-4 p-lg-4 "
      Image="./images/effectiveCollaboration.svg"
      ImageClass="aboutUsIndImage SetImage"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Effective Management"
      Heading4={true}
      IsSetClass={true}
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody pb-4 pb-md-0 textalignleft"
      Body="When any resource from our end is outsourced, it is crucial to have efficient management. We constantly maintain a single flow of communication with no management load on our clients."
    />
  );
  transperancyAndEthicsConent.push(effectiveCollaboration);
  var employeeBenefits = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4 mt-md-4 px-md-4"
      borClass="border10 bgF7F7F7 hover-hero-card-aboutus mb-3 px-4 p-lg-4 "
      ImageClass="aboutUsIndImage SetImage"
      Image="./images/pocketFriendlyApproch.svg"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Cost effective Approach"
      Heading4={true}
      IsSetClass={true}
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody pb-4 textalignleft"
      Body="We always take into account serving our clients at budget-friendly prices without sacrificing the quality of outcomes."
    />
  );
  transperancyAndEthicsConent.push(employeeBenefits);
  var teamWork = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4 mt-md-4 px-md-4"
      borClass="border10 bgF7F7F7 hover-hero-card-aboutus mb-3 px-4 p-lg-4 "
      ImageClass="aboutUsIndImage SetImage"
      Image="./images/teamWork.webp"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Deep-rooted Relations"
      Heading4={true}
      IsSetClass={true}
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody pb-4 textalignleft"
      Body="We always believe it is vital to build long-lasting relationships with our clients. This is directly related to the services we delivered to them test."
    />
  );
  transperancyAndEthicsConent.push(teamWork);
  var qualityOfService = (
    <BreadCrumbCard
      IsCard="false"
      ColSize="col-md-4 mt-md-4 px-md-4"
      borClass="border10 bgF7F7F7 hover-hero-card-aboutus mb-3 px-4 p-lg-4 "
      Image="./images/qualityOfService.webp"
      ImageClass="aboutUsIndImage SetImage"
      CustomHeaderClass="transperancyEthicsHeader"
      Header="Data Security"
      Heading4={true}
      IsSetClass={true}
      CustomClass=""
      CustomBodyClass="transperancyEthicsBody pb-4 textalignleft"
      Body="As part of the service-based sector, ensuring client data security is critical. We always comply with the NDA policy for clients as well as employees."
    />
  );
  transperancyAndEthicsConent.push(qualityOfService);
  var brandsWeWorkedForContent = (
    <div className="container-fluid p-0">
      <div className="colorWhite pt-lg-4 text-center ms-md-5 ms-1">
        <div className="row align-items-center pb-lg-5 pb-0">
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent
              alt="Berger's Table Pads & Table Cloth logo"
              title="Project Berger's Table Pads & Table Cloth"
              imgSrc="client1"
              hoverSrc="client1Hover"
            />
          </div>
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent
              alt="Gocoworq logo"
              title="Project Gocoworq "
              imgSrc="client2"
              hoverSrc="client2Hover"
            />
          </div>
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent
              alt="Talk&Save logo"
              title="Project Talk&Save"
              imgSrc="client3"
              hoverSrc="client3Hover"
            />
          </div>
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent
              alt="SecureRoofing logo"
              title="Project SecureRoofing"
              imgSrc="client4"
              hoverSrc="client4Hover"
            />
          </div>
        </div>
        <div className=" row align-items-center pb-lg-5 pb-0">
          <div className="col-md-3 col-6 my-lg-0 my-4">
            <HoverComponent
              alt="AddressTwo logo"
              title="Project AddressTwo "
              imgSrc="client5"
              hoverSrc="client5Hover"
            />
          </div>
          <div className="col-md-3 col-6  my-lg-0 my-3">
            <HoverComponent
              alt="alwajeeh logo"
              title="Project alwajeeh "
              imgSrc="client6"
              hoverSrc="client6Hover"
            />
          </div>
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent
              alt="Market Wagon logo"
              title="Project Market Wagon "
              imgSrc="client7"
              hoverSrc="client7Hover"
            />
          </div>
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent
              alt="Assured Electric logo"
              title="Project Assured Electric"
              imgSrc="client9"
              hoverSrc="client9Hover"
            />
          </div>
        </div>
        <div className="row align-items-center pb-lg-5 pb-0">
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent
              alt="Edge Technologies logo"
              title="Project  Edge Technologies "
              imgSrc="client8"
              hoverSrc="client8Hover"
            />
          </div>
          <div className="col-md-3 col-6  my-lg-0 my-4">
            <HoverComponent
              alt="KSS Insurance Solutions logo"
              title="Project KSS Insurance Solutions "
              imgSrc="client13"
              hoverSrc="client13Hover"
            />
          </div>
          <div className="col-md-3 col-6  my-lg-0 my-3">
            <HoverComponent
              alt="A Taste of Indiana logo"
              title="Project A Taste of Indiana "
              imgSrc="client11"
              hoverSrc="client11Hover"
            />
          </div>
          <div className="col-md-3 col-6 my-lg-0 my-3">
            <HoverComponent
              alt="CropTrac logo "
              title="Project CropTrac "
              imgSrc="client15"
              hoverSrc="client15Hover"
            />
          </div>
        </div>
        <div className="row align-items-center pb-lg-5 pb-0">
          <div className="col-md-3 col-6 text-center my-lg-0 my-3">
            <HoverComponent
              alt="tulamama logo"
              title="Project tulamama "
              imgSrc="client10"
              hoverSrc="client10Hover"
            />
          </div>
          <div className="col-md-3 col-6 text-center my-lg-0 my-3">
            <HoverComponent
              alt="Intelligent Living logo"
              title="Project Intelligent Living "
              imgSrc="client14"
              hoverSrc="client14Hover"
            />
          </div>
          <div className="col-md-3 col-6  my-lg-0 my-4">
            <HoverComponent
              alt="KS Lawn Care  logo"
              title="Project KS Lawn Care"
              imgSrc="client12"
              hoverSrc="client12Hover"
            />
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <HelmetMetaTag
        titleName="About Us | Software Development Company - SprigStack"
        desc="SprigStack provides comprehensive IT services for businesses of all sizes and is the go-to choice for expert solutions. Learn more about our company and team."
        canonical="https://sprigstack.com/about-us"
        ogtitle="About Us | Software Development Company - SprigStack"
        ogdesc="SprigStack provides comprehensive IT services for businesses of all sizes and is the go-to choice for expert solutions. Learn more about our company and team."
        ogtype="website"
        ogurl="https://sprigstack.com/about-us"
      />
      <div className="row pt-5">
        <div className="col-12 fontColorBlue text-center px-4">
          <h2 className="col-12 fw-600">
            Dedicated Team of Talented Professionals
          </h2>

          <img src="./images/PortfolioHR.png" alt="" className="img-fluid" />
          {width >= 991 ? (
            <LazyLoadingImg
              src="./images/aboutUsBanner.svg"
              alt="About Us infographic"
              title="About Us infographic"
            />
          ) : (
            <LazyLoadingImg
              src="./images/aboutPageMobileView.webp"
              className="aboutPageMobileViewBanner"
            />
          )}
        </div>
      </div>
      <div className="container-fluid px-0 pt-5">
        <div className="bgF7F7F7 pb-4">
          <div className="col-12 text-center px-4 py-4 pt-lg-5">
            <h1 className="clr0E2B3D fw-600">About SprigStack</h1>
            <h2 className="clr0E2B3D headerFontSizeH3">
              Robust and skilled IT engineering team
            </h2>
            <img src="images/hrImg.png" alt="" className="img-fluid"></img>
          </div>
          <div style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}>
            <div className="row mx-lg-5 " style={{ textAlign: "justify" }}>
              <div className="col-12 aufont2 ">
                SprigStack is a leading software and web development company
                that provides services by focusing on cutting-edge technologies.
                In addition, we are experts at addressing client requirements
                for top-notch solutions in developing websites, web apps, mobile
                apps, UI/UX design, API development and integration, SEO,
                DevOps, and project management.
              </div>
              <div className="col-12 aufont2 py-4">
                Since the initial stages, SprigStack has experienced significant
                growth and development. With over 7 years of experience in the
                software development industry, we consistently align ourselves
                with market trends, using our knowledge and skills to meet
                customer expectations.
              </div>
              <div className="col-12 aufont2 pb-4">
                Among the top software development companies on{" "}
                <a
                  href="https://clutch.co/profile/sprigstack"
                  rel="nofollow"
                  target="_blank"
                >
                  Clutch
                </a>
                ,{" "}
                <a
                  href="https://www.goodfirms.co/company/sprigstack"
                  rel="nofollow"
                  target="_blank"
                >
                  Goodfirms
                </a>
                , and{" "}
                <a
                  href="https://www.designrush.com/agency/profile/sprigstack"
                  rel="nofollow"
                  target="_blank"
                >
                  Designrush
                </a>
                , we're proud of our well-earned reputation for delivering
                exceptional work. Continuously producing fruitful results to
                fulfil our client's expectations is the hallmark of our success.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid" id="CountSetIntervalDiv" tabIndex="100">
        <div className="row auContainer py-4 px-6">
          <div className="col-lg-3 d-flex justify-content-center col-md-3 col-12 p-3 text-center">
            <div className="row">
              <div className="col-5 col-md-3 d-block d-md-flex align-self-center">
                <img src="/images/projects-completed.svg" alt="" />
              </div>
              <div
                style={{ textAlign: "left" }}
                className="col-7 col-md-9  AU_Font8 fontfff"
              >
                <div className="col-12">
                  <CountSetInterval
                    label={"count"}
                    number={countNumber.Count1}
                    duration={parseInt(2)}
                  />
                  +
                </div>
                <div className="col-12">
                  <div className="AU_Font6 fontfff">Projects Completed</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-flex justify-content-center col-md-3 col-12 p-3 text-center">
            <div className="row">
              <div className="col-5 col-md-4 d-flex align-self-center">
                <img src="/images/happy-clients.svg" alt="" />
              </div>
              <div
                style={{ textAlign: "left" }}
                className="col-7 col-md-8  AU_Font8 fontfff"
              >
                <div className="col-12">
                  <CountSetInterval
                    label={"count"}
                    number={countNumber.Count2}
                    duration={parseInt(2)}
                  />
                  +
                </div>
                <div className="col-12">
                  <div className="AU_Font6 fontfff">Happy Clients</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-flex justify-content-center col-md-3 col-12 p-3 text-center">
            <div className="row">
              <div className="col-5 col-md-4 d-flex align-self-center">
                <img src="/images/Experts-in-team.svg" alt="" />
              </div>
              <div
                style={{ textAlign: "left" }}
                className="col-7 col-md-8  AU_Font8 fontfff"
              >
                <div className="col-12">
                  <CountSetInterval
                    label={"count"}
                    number={countNumber.Count3}
                    duration={parseInt(2)}
                  />
                  +
                </div>
                <div className="col-12">
                  <div className="AU_Font6 fontfff">Experts in Team</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-flex justify-content-center col-md-3 col-12 p-3 text-center">
            <div className="row">
              <div className="col-5 col-md-4 d-flex align-self-center">
                <img src="/images/successful-years.svg" alt="" />
              </div>
              <div
                style={{ textAlign: "left" }}
                className="col-7 col-md-8  AU_Font8 fontfff"
              >
                <div className="col-12">
                  <CountSetInterval
                    label={"count"}
                    number={countNumber.Count4}
                    duration={parseInt(2)}
                  />
                  +
                </div>
                <div className="col-12">
                  <div className="AU_Font6 fontfff">Successful Years</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team Players*/}
      <div className="container-fluid" style={{ paddingTop: "3rem" }}>
        <div className="col-12 text-center">
          <h2 className="clr0E2B3D headerFontSizeH6">Meet The Team</h2>
          <img src="images/hrImg.png " alt="" className="img-fluid" />
        </div>
        <div className="row px-6 px-8 py-3 justify-content-center text-center">
          {teamPlayersData.map((data, i) => {
            return (
              <>
                {data.id <= 3 && (
                  <>
                    <div
                      className="col-lg-3 col-md-3 col-12 py-2 zclassHove"
                      key={data.id}
                    >
                      <img
                        src={`images/${data.avatarSrc}.jpg`}
                        alt={data.alt}
                        title={data.title}
                        loading="lazy"
                        className={`${data.customclass} img-fluid`}
                      />
                      <div className="col-12 mb-0 pt-4 ColorNavbar">
                        <h4 className="boldtext mb-0">{data.name} </h4>
                      </div>
                      <div className="col-12 pb-0 pt-2 TpPositionFont">
                        {data.position}{" "}
                      </div>
                      <div className="row pt-2 col-12 col-lg-6 m-auto px-5 text-center px-md-4">
                        <div className="col-6 socialLogo1">
                          <a href={data.mail} target="_blank">
                            <img src="images/mail final.svg" />
                          </a>
                        </div>
                        <div className="col-6 socialLogo2">
                          <a href={data.linkedin} target="_blank">
                            <img src="images/linkedin final.svg" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            );
          })}
        </div>
        {width >= 991 ? <div></div> : <div></div>}
      </div>
      <div className="container-fluid">
        <div className="row px-md-5 bgF7F7F7 pt-4 pb-md-5 HoverClass Feature">
          <div className="col-12 text-center px-4 py-3">
            <h2 className="clr0E2B3D fw-600">Get to Know Us Better</h2>
            <img src="images/hrImg.png" alt="" className="img-fluid"></img>
          </div>
          <div className="row ms-md-2 getToKnowCardContainer ">
            <div className="col-md-6 col-12 getToKnowCard  ">
              <div className="col-12 AU_Box px-3 py-4 my-2 hover-bgBlue">
                <h3 className="BlueClass fw-600 fs-18 mb-0">
                  The Inspiring Story of Our Founder
                </h3>
                <div className="row pt-2 " style={{ textAlign: "justify" }}>
                  <div className="fs-15">
                    The vision of our founder and domain knowledge are
                    fundamental to SprigStack's success. Our founder, Ronak
                    Shah, has been actively involved in the sector for over 12
                    years, and this experience has laid the foundation for our
                    team's journey. He consistently seeks and leverages market
                    opportunities to deliver the most remarkable outcomes.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 getToKnowCard">
              <div className="col-12 AU_Box px-3 py-4 my-2 hover-bgBlue">
                <h3 className="BlueClass fw-600 fs-18 mb-0">
                  Find Out What Makes Us Special
                </h3>
                <div
                  className="row pt-2 "
                  style={{ textAlign: "justify", height: "100%" }}
                >
                  <div className="fs-15">
                    Our primary differentiating quality is our competitiveness
                    and responsiveness to shifting market circumstances. Our key
                    priorities include delivering the finest solutions at
                    competitive prices, ensuring client satisfaction, and
                    adapting to technological changes.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 getToKnowCard">
              <div className="col-12 AU_Box px-3 py-4 my-2 hover-bgBlue">
                <h3 className="BlueClass fw-600 fs-18 mb-0">
                  Our Journey of Growth and Impact
                </h3>
                <div className="row pt-2 " style={{ textAlign: "justify" }}>
                  <div className="fs-15">
                    We have begun to provide USA-based clients with diverse
                    managed services. The main reason for our rapid growth is
                    our capability and flexibility in response to global market
                    changes. As a result, we now have satisfied customers in
                    more than ten countries, which is continuously growing.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 getToKnowCard">
              <div className="col-12 AU_Box px-3 py-4 my-2 hover-bgBlue">
                <h3 className="BlueClass fw-600 fs-18 mb-0">
                  People-First Culture
                </h3>
                <div className="row pt-2 ">
                  <div className="fs-15">
                    It is a well-known fact that the success of every company is
                    inherent in its employees. Similarly, the success of
                    SprigStack is due to our most competent team. We are really
                    fortunate to have an experienced team. As a result, the
                    working environment is regarded as the best for encouraging
                    innovation and creativity.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PersonalizedComp
        cardTitle="Ready to turn your idea into reality?<br/> Let's get started!"
        ContainerColor="cA49F9D"
        flag="2"
      />
      <ClientComp />
      <div className="pt-2"></div>
      <HeaderWithDynamicContent
        Header="Companies that have trusted us with their brand!"
        SubHeader="Some of the many global brands we have worked with."
        BgColor="#FFF"
        HeaderPadding="setlineheight"
        Content={brandsWeWorkedForContent}
        ContainerBottomPadding="pb-4"
        flag={true}
      />
      <HeaderWithDynamicContent
        Header="Why we are best in Industry!"
        SubHeader="Our adherence to high-quality standards and transparent processes make us the optimal choice."
        SubHeaderCss=" weBestSubHeading"
        HeaderPadding="setlineheight"
        Content={transperancyAndEthicsConent}
        pt={"pt"}
        isFullWidth={width >= 991 ? false : true}
      />
      <LifeAtSprigStack />
      <div className="pt-md-5"></div>
    </>
  );
}
