import React, { useReducer, useRef, useState } from "react";
import HoverComponent from "../Components/HoverComponent";
import { Link } from "react-router-dom";
import { formReducer, INITIAL_STATE } from "../Reducer/UserDetailsReduser";
import PhoneInput from "react-phone-input-2";
import $ from "jquery";
import Popups from "../Modals/Popups";
import PersonalizedActionModal from "../Modals/PersonalizedActionModal";
import ReCAPTCHA from "react-google-recaptcha";

export default function MpcReferral() {
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const ReCAPTCHAValue = useRef();
  const [show, setShow] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [country, setCountry] = useState(sessionStorage.getItem("Country"));
  const [successMsg, setSuccessMsg] = useState({
    errMsg: "",
    mailSentMsg: false,
  });

  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const ReferralSubmit = (e) => {
    e.preventDefault();
    setSuccessMsg({ ...successMsg, errMsg: "" });
    if ($("#ContactNumber").val().length < 8) {
      setSuccessMsg({
        ...successMsg,
        errMsg: "Please Enter Phone Number",
      });
    } else if (ReCAPTCHAValue.current.getValue() == "") {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please complete the captcha",
      });
    } else if ($("#ContactNumber").val().length < 8) {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please Enter Phone Number",
      });
    } else {
      var formdata = new FormData();
      formdata.append("Name", state.Name);
      formdata.append("ContactNumber", $("#ContactNumber").val());
      formdata.append("Email", state.Email);
      formdata.append("ProjectDetail", state.ProjectDetail);
      formdata.append("ProjectBrief", state.ProjectBrief);
      formdata.append("GoogleCaptcha", ReCAPTCHAValue.current.getValue());
      formdata.append("Subject", "From Referral Page");
      formdata.append("PageName", "/referral-page");
      var requestOptions = {
        method: "POST",
        body: formdata,
      };

      fetch("https://app.addresstwo.com/SprigstackEmail/Index", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var results = JSON.parse(result);
          if (results.Status == "Success" && results.Message == "Success") {
            setSuccessMsg({ ...successMsg, mailSentMsg: true });
          } else {
            console.log("Error:", results.Message);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const [isHover, setHover] = useState(false);

  return (
    <React.Fragment>
      <div className="container-fluid px-0 mpcrefferalpage">
        <div className="Herompcreferral position-relative pt-md-5 ">
          <div className="d-flex flex-lg-row flex-column px-6 mpcreferralheader justify-content-between align-items-start pt-md-4 pt-3">
            <div className="text-center text-md-start">
              {" "}
              <div>
                <h1 className="pt-2">
                  How SprigStack Enhanced MPC's
                  <br /> Online Store{" "}
                  <img
                    className="d-none d-md-inline"
                    src="./images/paw.png"
                    alt=""
                  />
                  <br />
                  <button
                    className="btn-mpcheader mt-2 mt-md-0"
                    onClick={() => {
                      window.open(
                        "/portfolio/ecommerce-website-development-for-my-pet-carnivore"
                      );
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    Read Full Case Study{" "}
                    <img src={`${isHover? "/images/leftRedArrow.svg" : "/images/personalizedWhiteArrow.svg"}`} alt="custom-arrow" />
                  </button>
                </h1>
              </div>
            </div>
            <div className=" text-center text-md-start mt-3 mt-md-0">
              <div className="d-flex flex-column mpcclient gap-3">
                <p className="m-0">Client Feedback:</p>
                <div className="d-flex gap-3">
                  <img
                    className="img-fluid cursor-pointer"
                    style={{ height: "100px", width: "100px" }}
                    src="/images/mpcrefferalvideoimg.png"
                    alt=""
                    onClick={() => {
                      setVideoPlaying(true);
                    }}
                  />
                  <div
                    className="d-flex flex-column justify-content-between flex-wrap ml-5" style={{fontSize: '16px'}}
                  >
                    <div className="fw-lighter" style={{fontSize: '12px'}}>
                      <div className="d-flex justify-content-center align-items-center gap-1" style={{fontSize: '16px'}}>
                        Josh - 
                        <p className="fw-lighter m-0" style={{fontSize: '12px'}}> 
                          General Manager at </p>
                      </div>
                      Tellos
                    </div>
                    <p
                      className="feedbackdiv cursor-pointer m-0"
                      onClick={() => {
                        setVideoPlaying(true);
                      }}
                    >
                      View feedback
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-white px-6 pt-5 mt-5">
            <div className="col-md-2 col-6 my-2 my-md-0 h-100">
              <div className="herosectionspanmpcrefferal d-flex justify-content-center">
              Custom Shopify <br/> Solution
              </div>
            </div>
            <div className="col-md-2 col-6 my-2 my-md-0 h-100">
              <div className="herosectionspanmpcrefferal d-flex justify-content-center">
                Improved User <br/> Experience
              </div>
            </div>
            <div className="col-md-2 col-6 my-2 my-md-0 h-100">
              <div className="herosectionspanmpcrefferal d-flex justify-content-center">
                Seamless <br/> Integration
              </div>
            </div>
            <div className="col-md-2 col-6 my-2 my-md-0 h-100">
              <div className="herosectionspanmpcrefferal d-flex justify-content-center">
                Innovative Delivery <br/> Solutions
              </div>
            </div>
            <div className="col-md-2 col-6 my-2 my-md-0 h-100">
              <div className="herosectionspanmpcrefferal d-flex justify-content-center">
                Automated <br/> Purchasing Process
              </div>
            </div>
            <div className="col-md-2 col-6 my-2 my-md-0 h-100">
              <div className="herosectionspanmpcrefferal d-flex justify-content-center">
                Faster Order <br/> Processing
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid p-0 mt-5">
          <h2 className="text-center">
            Companies that have trusted us with their brand!
          </h2>
          <div className="colorWhite d-flex align-items-center">
            <logo-slider style={{ marginTop: "0px" }}>
              <div>
                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="Berger's Table Pads & Table Cloth logo"
                    title="Our Project Berger's Table Pads & Table Cloth logo"
                    imgSrc="client1"
                    hoverSrc="client1Hover"
                  />
                </a>
                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="gocoworq logo"
                    title="Our Project gocoworq"
                    imgSrc="client2"
                    hoverSrc="client2Hover"
                  />
                </a>

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="talknsave logo"
                    title="Our Project  talknsave"
                    imgSrc="client3"
                    hoverSrc="client3Hover"
                  />
                </a>
                <HoverComponent
                  alt="secureroofing logo"
                  title="Our Project secureroofing"
                  imgSrc="client4"
                  hoverSrc="client4Hover"
                />

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="addresstwo logo"
                    title="Our Project addresstwo"
                    imgSrc="client5"
                    hoverSrc="client5Hover"
                  />
                </a>

                <HoverComponent
                  alt="alwajeeh logo"
                  title="Our Project alwajeeh"
                  imgSrc="client6"
                  hoverSrc="client6Hover"
                />

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="Marketwagon logo"
                    title="Our Project  Marketwagon"
                    imgSrc="client7"
                    hoverSrc="client7Hover"
                  />
                </a>
              </div>
              <div>
                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="Berger's Table Pads & Table Cloth logo"
                    title="Our Project Berger's Table Pads & Table Cloth logo"
                    imgSrc="client1"
                    hoverSrc="client1Hover"
                  />
                </a>

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="gocoworq logo"
                    title="Our Project gocoworq"
                    imgSrc="client2"
                    hoverSrc="client2Hover"
                  />
                </a>

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="talknsave logo"
                    title="Our Project  talknsave"
                    imgSrc="client3"
                    hoverSrc="client3Hover"
                  />
                </a>
                <HoverComponent
                  alt="secureroofing logo"
                  title="Our Project secureroofing"
                  imgSrc="client4"
                  hoverSrc="client4Hover"
                />

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="addresstwo logo"
                    title="Our Project addresstwo"
                    imgSrc="client5"
                    hoverSrc="client5Hover"
                  />
                </a>

                <HoverComponent
                  alt="alwajeeh logo"
                  title="Our Project alwajeeh"
                  imgSrc="client6"
                  hoverSrc="client6Hover"
                />

                <a className="d-flex align-items-center">
                  <HoverComponent
                    alt="Marketwagon logo"
                    title="Our Project  Marketwagon"
                    imgSrc="client7"
                    hoverSrc="client7Hover"
                  />
                </a>
              </div>
            </logo-slider>
          </div>
        </div>

        <div className="row px-6 my-5 pt-5">
          <h2 className="text-center">
            Ready to achieve similar results? Let's talk about your project.
          </h2>
          <div className="col-md-5 referralForm p-4 mt-3">
            {!successMsg.mailSentMsg ? (
              <>
                <h3 className="refFormText text-center">
                  Drop your details below, and we will contact you within 24
                  hours.
                </h3>

                <form onSubmit={ReferralSubmit} className="d-flex flex-column justify-content-between " style={{gap: '30px'}}>
                  <div className="col-lg-12 col-md-12 col-12 mt-4 position-relative">
                    <label htmlFor="Name" className="customLabel">
                      Name
                    </label>
                    <input
                      type="text"
                      className="customInput"
                      name="Name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 position-relative">
                    <label htmlFor="Email" className="customLabel">
                      Email
                    </label>
                    <input
                      type="email"
                      className="customInput"
                      name="Email"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 position-relative">
                    <div className="customPhoneInputWrapper">
                      <PhoneInput
                        country={country}
                        inputProps={{
                          name: "ContactNumber",
                          required: true,
                          id: "ContactNumber",
                          placeholder: "Phone Number*",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 position-relative">
                    <label htmlFor="ProjectDetail" className="customLabel">
                      Area of interest
                    </label>
                    <select
                      style={{ color: "inherit", height: "35px" }}
                      className="customInput"
                      name="ProjectDetail"
                      onChange={handleChange}
                      required
                    >
                      <option value="Area of interest">
                        Request a consultation
                      </option>
                      <option value="Hire Developers">Hire Developers</option>
                      <option value="Website Development">
                        Website Development
                      </option>
                      <option value="App Development">App Development</option>
                      <option value="API Integration Services">
                        API Integration Services
                      </option>
                      <option value="UI/UX project">UI/UX project</option>
                      <option value="SEO services">SEO services</option>
                      <option value="DevOps">DevOps</option>
                      <option value="Project Management">
                        Project Management
                      </option>
                      <option value="Project Estimation">
                        Project Estimation
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 position-relative">
                    <label htmlFor="ProjectBrief" className="customLabel">
                      Message
                    </label>
                    <textarea
                      className="customInput"
                      name="ProjectBrief"
                      rows="4"
                      placeholder="Let us know your project idea and we will make it reality"
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>{" "}
                  <div className="col-lg-12 col-md-12 col-12 justify-content-center align-items-center d-flex flex-column">
                    <ReCAPTCHA
                      ref={ReCAPTCHAValue}
                      sitekey={process.env.REACT_APP_EMAIL_RECAPTCHA_SITE_KEY}
                    />

                    <span className={`${successMsg.reCaptchaErrMsg ? 'my-2 text-danger' : ''}`}>
                      {successMsg.reCaptchaErrMsg}
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-12 text-center m-auto">
                      <input
                        style={{ background: "#b60117", border: "0px" }}
                        className="referralBtn"
                        type="submit"
                        value="Send"
                      />
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <>
                <div className="text-center h3">
                  Thank you for reaching out to us. We will get back to you
                  shortly!
                </div>
              </>
            )}
          </div>
          <div className="col-md-1 OrText d-flex justify-content-center align-items-center my-3 my-md-0">
            OR
          </div>
          <div className="col-md-6 referralForm p-4 mt-3">
            <h3 className="refFormText text-center position-absolute">
              Get started right away by scheduling a free consultation call at
              your convenience.
            </h3>
            <div
              className="calendly-inline-widget calender pt-5 mt-2"
              data-url="https://calendly.com/sprigstack-consultation/30min"
            ></div>
          </div>
        </div>

        <h2 className="serviceTxt text-center pt-md-4">
            Services provided by SprigStack
          </h2>
        <div className="serviceDiv px-6">
          <div className="d-flex flex-column gap-4 ">
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              
            <div className="custom-pe-0 d-flex justify-content-center align-items-center">
              <p className="position-absolute text-center text-white fs-5">App <br/>Development</p>
              <Link to="/mobile-app-development">
                <img
                  src="/images/referralimages/image 1.svg"
                  className="img-fluid"
                  alt="app-development"
                  />
              </Link>
            </div>
            <div className="custom-pe-0 d-flex justify-content-center align-items-center">
            <p className="position-absolute text-center text-white fs-5">API <br/>Integration</p>

              <Link to="/api-integration-services">
                <img
                  src="/images/referralimages/image 2.svg"
                  className="img-fluid"
                  alt="api-integration"
                  />
              </Link>
            </div>
            <div className="custom-pe-0 d-flex justify-content-center align-items-center">
            <p className="position-absolute text-center text-white fs-5">Web Development</p>

              <Link to="/web-development">
                <img
                  src="/images/referralimages/image 3.svg"
                  className="img-fluid"
                  alt="web development"
                  />
              </Link>
            </div>
            <div className="custom-pe-0 d-flex justify-content-center align-items-center">
            <p className="position-absolute text-center text-white fs-5">UI/UX Design</p>

              <Link to="/uiux-design">
                <img
                  src="/images/referralimages/image 4.svg"
                  className="img-fluid"
                  alt="ui/ux"
                  />
              </Link>
            </div>
                  </div>
                  <div className="d-flex flex-column flex-lg-row justify-content-between align-items-md-start">

            <div className="custom-pe-0 d-flex justify-content-center align-items-center">
            <p className="position-absolute text-center text-white fs-5">Devops</p>

              <Link to="/devops">
                <img
                  src="/images/referralimages/image 5.svg"
                  className="img-fluid"
                  alt="devops"
                  />
              </Link>
            </div>
            <div className=" d-flex justify-content-center align-items-center">
            <p className="position-absolute text-center text-white fs-5">Our Portfolio</p>

              <Link to="/portfolio">
                <img
                  src="/images/referralimages/image 6.svg"
                  className="img-fluid"
                  alt="portfolio"
                  />
              </Link>
            </div>
            <div className="custom-pe-0  d-flex justify-content-center align-items-center">
            <p className="position-absolute text-center text-white fs-5">Project <br/>Management</p>

              <Link to="/project-management ">
                <img
                  src="/images/referralimages/image 7.svg"
                  className="img-fluid"
                  alt="project management"
                  />
              </Link>
            </div>
            <div className=" custom-pe-0 d-flex justify-content-center align-items-center">
            <p className="position-absolute text-center text-white fs-5 mt-3 ms-1">SEO</p>

              <Link to="/seo">
                <img
                  src="/images/referralimages/image 8.svg"
                  className="img-fluid"
                  alt="seo"
                  />
              </Link>
            </div>
                  </div>
          </div>
        </div>

        <div className="px-6 Ctak12Refferal mb-4 d-flex align-items-center justify-content-center flex-column">
          Want a Unique Online Store?
          <br /> Let's Build Your Custom Ecommerce Website.
          <br />
          <br />
          <button
            className="btn-mpcheader"
            onClick={() => {
              setShow(true);
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            Get a free consulation{" "}
            <img src={`${isHover? "/images/leftRedArrow.svg" : "/images/personalizedWhiteArrow.svg"}`} alt="custom-arrow-" />
          </button>
        </div>
      </div>
      <Popups show={show} setShow={setShow} title="FREE CONSULTATION" size="lg">
        <PersonalizedActionModal />
      </Popups>
      {videoPlaying && (
        <div className="video-wrapper col-md-12">
          <div className="video-container">
            <div className="videoClose" onClick={() => setVideoPlaying(false)}>
              X
            </div>
            <iframe
              className="video-iframe"
              src="https://www.youtube.com/embed/suR2KwSPuxc?si=hywGRt1TpTUE5GZi"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
