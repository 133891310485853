import React, { useEffect, useReducer, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import $ from "jquery";
import { INITIAL_STATE, formReducer } from "../../Reducer/UserDetailsReduser";
import CountSetInterval from "../../Components/CounterComp";
import { HeaderWithDynamicContent } from "../../Components/HeaderWithDynamicContent";
import { BreadCrumbCard } from "../../Components/BreadCrumbCard";
import { NamePlateCard } from "../../Components/NamePlateCard";
import { DevelopmentRecentWork } from "../../Components/DevelopmentWork";
import NewClientComp from "../NewClientComp";
import HelmetMetaTag from "../../Components/HelmetMetaTag";
import PersonalizedActionModal from "../../Modals/PersonalizedActionModal";
import Popups from "../../Modals/Popups";
import { MobileDevelopmentWork } from "../../Components/MobileDevelopmentWork";
import { Link, useNavigate } from "react-router-dom";

export default function NewYorkMobileDevelopment() {
  const [windowWidth, setWindowWidth] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });
  const navigate = useNavigate();
  const dataSets = [
    {
      key: "1",
      videoUrl: "https://www.youtube.com/embed/dPdWTzngcwQ",
      Name: "Ryan Thomas",
      Role: "Operations Manager at Market Wagon - USA",
      textContent:
        "Market Wagon was in its initial stage of development when we first met Ronak. He and his team worked with us from concept to reality. Initially, we began with Shopify, and eventually, Ronak and his team flawlessly migrated the entire application to the Laravel tech stack.Our journey wouldn't have been the same without the expertise of the SprigStack team.",
    },

    {
      key: "2",
      videoUrl: "https://www.youtube.com/embed/2tdaEvZ9znw ",
      Name: "Bill Burke",
      Role: "Founder at BoxScore - USA ",
      textContent:
        "Five years ago, we had an idea for an MVP and were searching for a software development firm. We are so glad we found Sprig Stack.They are responsive and have great software instincts. The application they built for us has extensive functionality, yet it remains simple for our clients to learn and use. Their amazing support continues to be an integral part of the product we offer to our clients to this day. We are incredibly thankful for our friends at SprigStack.",
    },

    {
      key: "3",
      img: "/images/DummyAvtar.png",
      Name: "Ryan Skidmore",
      Role: "Director at Berger's TablePads - USA",
      textContent:
        "We needed fast and efficient desktop and mobile websites for our products to sell online. Ronak and the team did a great job in achieving that for us.",
    },
  ];
    var recentWorkContent = (
      <>
      <DevelopmentRecentWork
              CustomImageClass="IndianaImgHeight"
              ImagePosition="right"
              CustomHeaderClass="recentWorkHeader Indiana"
              DescriptionHeader="Hybrid Application Development for Al-Wajeeh"
              Image="./images/SMBLmobielappdev.png"
              CustomBodyClass="recentWorkBody"
              cardcolor=" bor-48 my-md-4 m-0  mt-4 mb-1  RecentContainer"
              Description="Technologies Used : Laravel, pusher, React, AWS, twilio, MySql"
              customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0 bg77BFDD33 py-3"
              customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-6"
              RedirectUrl='https://www.addresstwo.com/'
              redirectNumber="/portfolio/hybrid-application-development-for-al-wajeeh"
              DevelopmentRecentWorkBtn={true}
              CardMargin="col-12 recentBox"
            />
        <DevelopmentRecentWork
              CustomImageClass="IndianaImgHeight"
              ImagePosition="left"
              CustomHeaderClass="recentWorkHeader Indiana"
              DescriptionHeader="Euroriente - One Stop Travel Guide"
              Image="./images/Eurorientmobileappdev.png"
              CustomBodyClass="recentWorkBody"
              cardcolor=" bor-48 my-md-4 m-0  mt-4 mb-1  RecentContainer"
              Description="Technologies Used : Flutter, Stripe APIs, Laravel, MySql"
              customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0 bg77BFDD33 py-3"
              customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-6"
              RedirectUrl="https://tablepads.com/"
              redirectNumber="/portfolio/development-of-an-erp-system-for-the-travel-industry"
              DevelopmentRecentWorkBtn={true}
              CardMargin="col-12 recentBox"
            />
        <DevelopmentRecentWork
              CustomImageClass="IndianaImgHeight"
              ImagePosition="right"
              CustomHeaderClass="recentWorkHeader Indiana"
              DescriptionHeader="Walletier - Payments Made Easy"
              Image="./images/walletiremobileappdev.png"
              CustomBodyClass="recentWorkBody"
              cardcolor=" bor-48 my-md-4 m-0  mt-4 mb-1  RecentContainer"
              Description="Technologies Used : React Native, Laravel, Payment Gateway APIs"
              customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0 bg77BFDD33 py-3"
              customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-6"
              RedirectUrl="https://tablepads.com/"
              CardMargin="col-12 recentBox"
            />
        <DevelopmentRecentWork
              CustomImageClass="IndianaImgHeight"
              ImagePosition="left"
              CustomHeaderClass="recentWorkHeader Indiana"
              DescriptionHeader="TreatMe - Doctor at your Fingertip"
              Image="./images/Treatmemobileappdev.png"
              CustomBodyClass="recentWorkBody"
              cardcolor=" bor-48 my-md-4 m-0  mt-4 mb-1  RecentContainer"
              Description="Technologies Used : React Native, Asp.net Core, Sql Server"
              customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0 bg77BFDD33 py-3"
              customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-6"
              RedirectUrl="https://tablepads.com/"
              CardMargin="col-12 recentBox"
            />
      </>
    );
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize"
      Image="./images/AppleIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="iPhone/iOS Apps"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext "
      Body="As the industry standard, iOS provides a powerful platform to showcase your business. A native iOS app can give you a competitive edge, signaling your company's credibility and inspiring trust in your brand."
      Division={true}
      DivisionCustomClass='mb-2'
      height={windowWidth <= 991 ? '1px' : '100%'}
      width={windowWidth <= 991 ? '100%' : '1px'}
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/AndroidIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Android Apps"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Android applications offer a vast user base, making them a powerful tool for businesses. With a significant portion of the market using Android devices, your app can reach a wider audience and drive substantial business growth."
      Division={true}
      DivisionCustomClass='mb-3'
      height={windowWidth <= 991 ? '1px' : '100%'}
      width={windowWidth <= 991 ? '100%' : '1px'}
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/ReactNativeIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="React Native Apps"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="React is a framework trusted by Facebook. React Native is one of the most modern and ongoing technologies for cross-platform applications. It gives the ability to build applications for both iOS and Android platforms at the same time."
      Division={windowWidth <= 991 ? true : false}
      height={windowWidth <= 991 ? '1px' : '100%'}
      width={windowWidth <= 991 ? '100%' : '1px'}
    />
  );
  serviceConent.push(
    <>
    <span style={{width: windowWidth <= 991 ? '0px': '100%', height: windowWidth <= 991 ? '0' : '1px', backgroundColor: '#c4c4c4'}}/>
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize h-100"
      Image="./images/FlutterIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Flutter Apps"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Flutter is a unique and efficient solution for creating cross-platform apps for your business. One technology handles both iOS and Android operating system apps concurrently. Flutter is trusted by google for exceptional performance."
      Division={true}
      height={windowWidth <= 991 ? '1px' : '100%'}
      width={windowWidth <= 991 ? '100%' : '1px'}
      />
      </>
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/XemerinIcon.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Xamarin in Apps"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="When it comes to cross-platform technologies, Microsoft is not far behind. Microsoft's Xamarin platform allows programmers to create a single application for iOS and Android devices. It is a perfectly useful and established cross-platform technique for developing mobile apps."
      Division={true}
      DivisionCustomClass='mb-4'
      height={windowWidth <= 991 ? '1px' : '100%'}
      width={windowWidth <= 991 ? '100%' : '1px'}
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/MobileHybrid.svg"
      CustomHeaderClass="TpPlaceFont clr0E2B3D align-self-center"
      Header="Hybrid Mobile Apps"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Web applications can be easily modified into hybrid mobile applications. It is a widely used approach as this enables a user to access the app from any platform. Hybrid app creation is a money saver than developing individual mobile apps for each platform."
    />
  );
  const [countNumber, setCountNumber] = useState({
    Count1: 0,
    Count2: 0,
    Count3: 0,
    Count4: 0,
  });
  useEffect(() => {
    window.addEventListener("scroll", CounterFun);
    return () => {
      window.removeEventListener("scroll", CounterFun);
    };
  }, []);
  const CounterFun = () => {
    const scrollTop = window.scrollY;

    if (scrollTop >= 0) {
      setCountNumber({
        ...countNumber,
        Count1: 100,
        Count2: 50,
        Count3: 20,
        Count4: 8,
      });
    }
  };
  const [country, setCountry] = useState(sessionStorage.getItem("Country"));
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [successMsg, setSuccessMsg] = useState({
    reCaptchaErrMsg: "",
    mailSentMsg: false,
  });
  const ReCAPTCHAValue = useRef();
  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const CUSubmit = (e) => {
    e.preventDefault();
    setSuccessMsg({ ...successMsg, reCaptchaErrMsg: "" });
    if (ReCAPTCHAValue.current.getValue() == "") {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please complete the captcha",
      });
    } else if ($("#ContactNumber").val().length < 8) {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please Enter Phone Number",
      });
    } else {
      var formdata = new FormData();
      formdata.append("Name", state.Name);
      formdata.append("ContactNumber", $("#ContactNumber").val());
      formdata.append("Email", state.Email);
      formdata.append("ProjectDetail", state.ProjectDetail);
      formdata.append("ProjectBrief", state.ProjectBrief);
      formdata.append("GoogleCaptcha", ReCAPTCHAValue.current.getValue());
      formdata.append("Subject", "From Contact Us Page");
      formdata.append("PageName", "Indiana Page");
      var requestOptions = {
        method: "POST",
        body: formdata,
      };
      fetch("https://app.addresstwo.com/SprigstackEmail/Index", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var results = JSON.parse(result);
          if (results.Status == "Success" && results.Message == "Success")
            setSuccessMsg({ ...successMsg, mailSentMsg: true });
          else if (
            results.Status == "Failed" &&
            results.Message == "Please Validate Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Please Validate Captcha",
            });
          else if (
            results.Status == "Failed" &&
            results.Message == "Error in Verify Google Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Error in Verify Google Captcha",
            });
        })
        .catch((error) => error.throw(error));
    }
  };
  return (
    <React.Fragment>
      <HelmetMetaTag
        titleName="Custom Mobile App Development Company in New York"
        desc="Get an app that sets your business apart with our mobile app development services in New York. We offer iOS/Android, React Native, Flutter, and Hybrid apps."
        canonical={window?.location?.href}
        ogtitle="Custom Mobile App Development Company in New York"
        ogdesc="Get an app that sets your business apart with our mobile app development services in New York. We offer iOS/Android, React Native, Flutter, and Hybrid apps."
        ogtype="website"
        ogurl={window?.location?.href}
      />
      <div className="row px-6 pb-md-5 pb-0 bg-262626 LocationIndianaHeroBg">
        <div className="col-12 col-md-6 mt-5 mt-md-0 d-flex justify-content-center align-items-center  flex-column IndianaHeader">
          <h1>Mobile App Development Company in New York</h1>
          <p className=" mt-3 p-0 m-0 mb-3">
            As a leading mobile app development company in New York, we provide
            cutting-edge <span onClick={() => navigate('/mobile-app-development')} className="text-decoration-none IndianaTpPlaceFont p-0 cursor-pointer">mobile app development services</span> for iOS and Android
            platforms.
          </p>
          <p className="mb-0">
            Our mobile app developers in New York craft custom mobile
            applications that not only meet your specific business needs but
            also deliver an exceptional user experience. Leveraging the latest
            tools and technologies, we ensure rapid development and deployment.
            With years of industry experience, we offer seamless and
            personalized services to help your business harness the power of
            mobile technology.
          </p>
          <br />
          <div className="pb-md-5" style={{ width: "100%" }}>
            {" "}
            <a
              href="https://clutch.co/profile/sprigstack"
              rel="nofollow"
              target="_blank"
            >
              <img
                src="/images/clutch.svg"
                className="img-fluid pe-4  crsr-pointer"
                alt="Clutch icon"
                title="Clutch SprigStack Profile"
              />
            </a>
            <a
              href="https://www.designrush.com/agency/profile/sprigstack"
              rel="nofollow"
              target="_blank"
            >
              <img
                src="/images/sprigstack_designrush.svg"
                className="img-fluid  pe-4  crsr-pointer mt-1"
                alt="Designrush icon"
                title="Designrush SprigStack Profile"
              />
            </a>
          </div>
        </div>
        <div className="col-12 col-md-6  my-5 mx-0  d-md-flex d-block justify-content-center align-items-center">
          {!successMsg.mailSentMsg ? (
            <div
              style={{
                paddingLeft: windowWidth <= 991 ? "" : "160px",
                paddingRight: windowWidth <= 991 ? "" : "80px",
              }}
            >
              <form className="IndianaForm bg-white" onSubmit={CUSubmit}>
                <div className="row px-3 pb-3">
                  <div className="col-12 my-3 IndianaQuote">
                    Get Free Consultation
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name*"
                      onChange={handleChange}
                      name="Name"
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email ID*"
                      onChange={handleChange}
                      name="Email"
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <PhoneInput
                      country={country}
                      autoFormat="true"
                      inputProps={{
                        name: "ContactNumber",
                        required: true,
                        id: "ContactNumber",
                        className: " form-control",
                      }}
                      placeholder="Phone Number*"
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <select
                      id="ProjectDetail"
                      name="ProjectDetail"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option defaultChecked value="Request a consultation">
                        Request a consultation
                      </option>
                      <option value="Hire Developers">Hire Developers</option>
                      <option value="Website Development">
                        Website Development
                      </option>
                      <option value="App Development">App Development</option>
                      <option value="API Integration Services">
                        API Integration Services
                      </option>
                      <option value="UI/UX project">UI/UX project</option>
                      <option value="SEO services">SEO services</option>
                      <option value="DevOps">DevOps</option>
                      <option value="Project Management">
                        Project Management
                      </option>
                      <option value="Project Estimation">
                        Project Estimation
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-12 mb-3">
                    <textarea
                      className="form-control"
                      placeholder="How can we help you?"
                      onChange={handleChange}
                      name="ProjectBrief"
                      rows="4"
                    />
                  </div>
                  <div className="col-12 justify-content-center d-flex ">
                    <ReCAPTCHA
                      ref={ReCAPTCHAValue}
                      sitekey={process.env.REACT_APP_EMAIL_RECAPTCHA_SITE_KEY}
                    />
                  </div>
                  <div className="col-12 justify-content-center d-flex">
                    <span className="text-danger my-2">
                      {successMsg.reCaptchaErrMsg}
                    </span>
                  </div>
                  <div className="col-12 text-center mb-md-0 mb-3">
                    <button
                      className="btn btn-primary phpModalBtn"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <>
              <form
                style={{ height: windowWidth <= 991 ? "" : "400px" }}
                className="IndianaForm bg-white d-flex justify-content-center align-items-center"
              >
                <div
                  className="text-center h3 bg-white mx-5 py-5"
                  style={{ fontSize: windowWidth <= 991 ? "17px" : "24px" }}
                >
                  Thank you for reaching out to us. We will get back to you
                  shortly!
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      {windowWidth <= 991 ? (
        <div className="row px-6 py-5 pb-4">
          <div className="col-6  text-center ">
            <div className="ProjectsCount pt-2">
              <div className="col-12 CountProject ">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count1}
                  duration={parseInt(2)}
                />
                +
              </div>
              <div className="col-12 ProjectTitle">Projects Completed</div>
            </div>
          </div>
          <div className="col-6 text-center d-flex ">
            <div className="ProjectsCount pt-2">
              <div className="col-12 CountProject ">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count2}
                  duration={parseInt(2)}
                />
                +
              </div>
              <div className="col-12 ProjectTitle">Happy Clients</div>
            </div>
          </div>
          <div className="col-6 my-4  text-center d-flex ">
            <div className="ProjectsCount pt-2">
              <div className="col-12 CountProject ">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count3}
                  duration={parseInt(2)}
                />
                +
              </div>
              <div className="col-12 ProjectTitle">Experts in Team</div>
            </div>
          </div>
          <div className="col-6 my-4  text-center d-flex ">
            <div className="ProjectsCount pt-2">
              <div className="col-12 CountProject ">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count4}
                  duration={parseInt(2)}
                />
                +
              </div>
              <div className="col-12 ProjectTitle">Years of Trust</div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" position-relative">
          <div className="row px-6 width100 position-absolute  top-60">
            <div className=" d-flex align-items-center ">
              <div className="col-3  text-center d-flex justify-content-center">
                <div className="ProjectsCount pt-2">
                  <div className="col-12 CountProject">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count1}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12 ProjectTitle">Projects Completed</div>
                </div>
              </div>
              <div className="col-3  text-center d-flex justify-content-center">
                <div className="ProjectsCount pt-2">
                  <div className="col-12 CountProject">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count2}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12 ProjectTitle">Happy Clients</div>
                </div>
              </div>
              <div className="col-3  text-center d-flex justify-content-center">
                <div className="ProjectsCount pt-2">
                  <div className="col-12 CountProject">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count3}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12 ProjectTitle">Experts in Team</div>
                </div>
              </div>
              <div className="col-3  text-center d-flex justify-content-center">
                <div className="ProjectsCount pt-2">
                  <div className="col-12 CountProject">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count4}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12 ProjectTitle">Years of Trust</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="position-absolute"><HeroSectionProjects /></div> */}
        </div>
      )}

      <div className="row px-6">
        {windowWidth <= 991 ? (
          <HeaderWithDynamicContent
            pt="true"
            flag={true}
            HrLine="true"
            BgColor=""
            Header="Our Key App Development <br/>Services in New York"
            ContainerBottomPadding="pb-md-3 pt-md-5 pt-0"
            HeaderPadding="ourKeyHeading"
            Content={[serviceConent]}
          />
        ) : (
          <HeaderWithDynamicContent
            HrLine="true"
            BgColor=""
            Header="Our Key App Development <br/>Services in New York"
            ContainerBottomPadding="pb-md-3 pt-md-5 pt-0"
            HeaderPadding="ourKeyHeading"
            Content={[serviceConent]}
          />
        )}
      </div>
      <div className="row px-6">
        <HeaderWithDynamicContent
          pt={windowWidth <= 911 ? "true" : ""}
          HrLine="true"
          BgColor=""
          Header="Major Benefits of Mobile Applications"
          ContainerBottomPadding="pb-md-3 pt-0"
          HeaderPadding=""
          flag={true}
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/webAppIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Better and EasyAccess for Users"
          CustomClass="MajorBenefitsContainer"
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/crossPlatformIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Strong Identity"
          CustomClass="MajorBenefitsContainer"
          alt="Cross-Platform Icon"
          title="Cross-Platform "
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/manageIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Offline ContentOffering"
          CustomClass="MajorBenefitsContainer"
          alt="Manage Icon"
          title="Manage "
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/deployIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Boosted UserFriendliness"
          CustomClass="MajorBenefitsContainer"
          alt="Deploy Icon"
          title="Deploy"
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/secureIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Inclusive of ComplexFeatures"
          CustomClass="MajorBenefitsContainer"
          alt="Secure Icon"
          title="Secure"
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/redcuedCostIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Value for Money"
          CustomClass="MajorBenefitsContainer"
          alt="Reduced Cost Icon"
          title="Reduced Cost"
        />
      </div>
      <div className="row px-6 bgF7F7F7">
        <HeaderWithDynamicContent
          HrLine="true"
          BgColor=""
          Header="Mobile App Development Process that We Follow"
          ContainerBottomPadding="pb-md-3 pt-0"
          HeaderPadding=""
          flag={true}
        />
      </div>
      <div className="row px-6 bgF7F7F7 pb-4">
        <>
          <div className="col-md-3 col-12 ">
            <div className="row m-2 processDynamicDiv">
              <div className="col-12 text-center1">
                <div className="processCircle mt-4">
                  <img
                    className="img-fluid "
                    src="/images/processWebIcon1.svg"
                    alt="Web Process Icon 1"
                    title="Web Process 1 - SprigStack"
                  />
                </div>
                <h3
                  className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                >
                  1. Requirement Gathering
                </h3>
              </div>
              <div className="col-12 pb-0 mb-0">
                <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                  The first step for building an impactful website or
                  application is putting a well-defined requirement together.
                  That is the first and key step we follow.
                </div>
              </div>
            </div>
            <div className="row m-2 position-relative">
              <div className="col-12 processCenter"></div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-4 mt-md-0">
            <div className="row m-2 processDynamicDiv">
              <div className="col-12 text-center1">
                <div className="processCircle mt-4">
                  <img
                    className="img-fluid "
                    src="/images/processWebIcon2.svg"
                    alt="Web Process Icon 2"
                    title="Web Process 2 - SprigStack"
                  />
                </div>
                <h3
                  className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                >
                  2. Defining Technology
                </h3>
              </div>
              <div className="col-12 pb-0 mb-0">
                <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                  Websites can be built with numerous different technologies. We
                  choose the best suitable according to the needs and
                  capabilities of the website we are building.
                </div>
              </div>
            </div>
            <div className="row m-2 position-relative">
              <div className="col-12 processCenter"></div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-4 mt-md-0">
            <div className="row m-2 processDynamicDiv">
              <div className="col-12 text-center1">
                <div className="processCircle mt-4">
                  <img
                    className="img-fluid "
                    src="/images/processWebIcon3.svg"
                    alt="Web Process Icon 3"
                    title="Web Process 3 - SprigStack"
                  />
                </div>
                <h3
                  className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                >
                  3. UI/UX Design
                </h3>
              </div>
              <div className="col-12 pb-0 mb-0">
                <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                  Look & feel are very important for websites to attract more
                  and more users. Overall, experience and user-friendliness are
                  equally important. Website development begins with this step.
                </div>
              </div>
            </div>
            <div className="row m-2 position-relative">
              <div className="col-12 processCenter"></div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-4 mt-md-0">
            <div className="row m-2 processDynamicDiv">
              <div className="col-12 text-center1">
                <div className="processCircle mt-4">
                  <img
                    className="img-fluid "
                    src="/images/processWebIcon4.svg"
                    alt="Web Process Icon 4"
                    title="Web Process 4 - SprigStack"
                  />
                </div>
                <h3
                  className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                >
                  4. Prototype
                </h3>
              </div>
              <div className="col-12 pb-0 mb-0">
                <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                  Based on the theme and look we finalized in the previous step,
                  we provide a working prototype to clients to let them
                  understand the result.
                </div>
              </div>
            </div>
            <div className="row m-2 position-relative">
              <div className="col-12 processCenter"></div>
            </div>
          </div>
          <div className="row d-md-flex d-block justify-content-center p-0 m-0">
            <div className="col-md-3 col-12 mt-4 my-md-4 my-0">
              <div className="row m-2 processDynamicDiv">
                <div className="col-12 text-center1">
                  <div className="processCircle mt-4">
                    <img
                      className="img-fluid "
                      src="/images/processWebIcon5.svg"
                      alt="Web Process Icon 5"
                      title="Web Process 5 - SprigStack"
                    />
                  </div>
                  <h3
                    className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                  >
                    5. Development & QA
                  </h3>
                </div>
                <div className="col-12 pb-3 pb-md-0 mb-0">
                  <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                    The development phase starts as soon as requirements are
                    clear and some designs are finalized. Experts must do this
                    since it is the heart of any web application.
                  </div>
                </div>
              </div>
              <div className="row m-2 position-relative">
                <div className="col-12 processCenter"></div>
              </div>
            </div>
            <div className="col-md-3 col-12 mt-4 my-md-4 my-0">
              <div className="row m-2 processDynamicDiv">
                <div className="col-12 text-center1">
                  <div className="processCircle mt-4">
                    <img
                      className="img-fluid "
                      src="/images/processWebIcon6.svg"
                      alt="Web Process Icon 6"
                      title="Web Process 6 - SprigStack"
                    />
                  </div>
                  <h3
                    className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                  >
                    6. Deployment
                  </h3>
                </div>
                <div className="col-12 pb-3 pb-md-0 mb-0">
                  <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                    When the development & QA process is complete and the
                    website is ready to be moved to the production server, that
                    phase is called Deployment. We take care of it and make sure
                    the transition is smooth.
                  </div>
                </div>
              </div>
              <div className="row m-2 position-relative">
                <div className="col-12 processCenter"></div>
              </div>
            </div>
            <div className="col-md-3 col-12 mt-4 my-md-4 my-0">
              <div className="row m-2 processDynamicDiv">
                <div className="col-12 text-center1">
                  <div className="processCircle mt-4">
                    <img
                      className="img-fluid "
                      src="/images/processWebIcon7.svg"
                      alt="Web Process Icon 7"
                      title="Web Process 7 - SprigStack"
                    />
                  </div>
                  <h3
                    className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                  >
                    7. Support & Maintenance
                  </h3>
                </div>
                <div className="col-12 pb-3 pb-md-0 mb-0">
                  <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                    After successful deployment on the production server, we
                    ensure everything works as expected and maintain them as
                    long as clients want us to. We have a 100% maintenance
                    retention ratio for our work.
                  </div>
                </div>
              </div>
              <div className="row m-2 position-relative">
                <div className="col-12 processCenter"></div>
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="row ">
        <HeaderWithDynamicContent
          HrLine="true"
          BgColor=""
          Header="Technologies Used for Mobile Apps"
          ContainerBottomPadding="pb-md-3 pt-0"
          HeaderPadding=""
          flag={true}
        />
        <div className="col-12 text-center">
          <img src="/images/mobiletechstack.png" className="img-fluid" alt="" />
        </div>
      </div>
      <div className="IndianBgCta my-3 ">
        <p>
          Transform your business with our <br />
          custom mobile app development
          <br /> services
        </p>
        <button
          className="btn-mpcheader mb-5"
          style={{ background: "#FFFFFF", color: "#EE720B" }}
          onClick={() => {
            setShow(true);
          }}
          >
          Get a free consulation{" "}
          <img src="/images/personalizedArrow.svg" alt="" />
        </button>
      </div>
      <HeaderWithDynamicContent
        pt={windowWidth <= 911 ? "true" : ""}
        BgColor=""
        Header="Recent Mobile Apps Made by Us"
        HeaderPadding="RecentWorkHeading"
        ContentCustomClass=""
        ContainerBottomPadding="RecentworkPadding"
        flag={true}
        Content={[recentWorkContent]}
      />
      <NewClientComp dataSets={dataSets} />
      <HeaderWithDynamicContent
        pt={windowWidth <= 911 ? "true" : ""}
        BgColor=""
        Header="Why Choose Us for Mobile App Development in New York City?"
        HeaderPadding="RecentWorkHeading"
        ContentCustomClass=""
        ContainerBottomPadding="RecentworkPadding"
        flag={true}
      />
      <div className="row px-6 my-5 pb-md-5 pb-0">
        <div className="col-md-4 col-12 ">
          <div className="row IndianaWhyChoose px-3 m-auto">
            <div className="col-12 my-4">
              <img
                src="/images/web-devChooseUsIcon1.svg"
                alt="Widely Experienced Team"
                title="Widely Experienced Team"
              />
            </div>
            <div className="col-12 WhyChooseIndiana ">
              Widely Experienced Team
              <p className="my-4 mb-5">
                Have your website built with our expert web development team and
                be assured for the great result. Experience is the key here.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mt-4 mt-md-0">
          <div className="row IndianaWhyChoose px-3 m-auto">
            <div className="col-12 my-4">
              <img
                src="/images/web-devChooseUsIcon2.svg"
                alt="Top Notch Security"
                title="Top Notch Security"
              />
            </div>
            <div className="col-12 WhyChooseIndiana ">
              Top Notch Security
              <p className="my-4 mb-5">
                When you work with us, your idea and data are secured intact. We
                do NDA with all of our clients and share the same process with
                our employees.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mt-4 mt-md-0">
          <div className="row IndianaWhyChoose px-3 m-auto">
            <div className="col-12 my-4">
              <img
                src="/images/web-devChooseUsIcon3.svg"
                alt="100% Assured Quality Output"
                title="100% Assured Quality Output"
              />
            </div>
            <div className="col-12 WhyChooseIndiana ">
              100% Assured Quality Output
              <p className="my-4 mb-5">
                We are known for our quality output and support. No client is
                big or small for us, all of them get 100% assurance in terms of
                quality and result.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Popups show={show} setShow={setShow} title="FREE CONSULTATION" size="lg">
        <PersonalizedActionModal />
      </Popups>
    </React.Fragment>
  );
}
