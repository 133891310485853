import React, { useEffect, useReducer, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import $ from "jquery";
import { INITIAL_STATE, formReducer } from "../../Reducer/UserDetailsReduser";
import CountSetInterval from "../../Components/CounterComp";
import { HeaderWithDynamicContent } from "../../Components/HeaderWithDynamicContent";
import { BreadCrumbCard } from "../../Components/BreadCrumbCard";
import { NamePlateCard } from "../../Components/NamePlateCard";
import { DevelopmentRecentWork } from "../../Components/DevelopmentWork";
import NewClientComp from "../NewClientComp";
import HelmetMetaTag from "../../Components/HelmetMetaTag";
import PersonalizedActionModal from "../../Modals/PersonalizedActionModal";
import Popups from "../../Modals/Popups";
import { useNavigate } from "react-router-dom";

export default function SanFransiscoWebDevelopment() {
  const [windowWidth, setWindowWidth] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
  });

  const navigate = useNavigate()
  const dataSets = [
    {
      key: "1",
      videoUrl: "https://www.youtube.com/embed/dPdWTzngcwQ",
      Name: "Ryan Thomas",
      Role: "Operations Manager at Market Wagon - USA",
      textContent:
        "Market Wagon was in its initial stage of development when we first met Ronak. He and his team worked with us from concept to reality. Initially, we began with Shopify, and eventually, Ronak and his team flawlessly migrated the entire application to the Laravel tech stack.Our journey wouldn't have been the same without the expertise of the SprigStack team.",
    },

    {
      key: "2",
      videoUrl: "https://www.youtube.com/embed/2tdaEvZ9znw ",
      Name: "Bill Burke",
      Role: "Founder at BoxScore - USA ",
      textContent:
        "Five years ago, we had an idea for an MVP and were searching for a software development firm. We are so glad we found Sprig Stack.They are responsive and have great software instincts. The application they built for us has extensive functionality, yet it remains simple for our clients to learn and use. Their amazing support continues to be an integral part of the product we offer to our clients to this day. We are incredibly thankful for our friends at SprigStack.",
    },

    {
      key: "3",
      img: "/images/DummyAvtar.png",
      Name: "Ryan Skidmore",
      Role: "Director at Berger's TablePads - USA",
      textContent:
        "We needed fast and efficient desktop and mobile websites for our products to sell online. Ronak and the team did a great job in achieving that for us.",
    },
  ];
  var recentWorkContent = (
    <>
    <DevelopmentRecentWork
        CustomImageClass="IndianaImgHeight"
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader Indiana"
        DescriptionHeader="AddressTwo - Small Business CRM"
        Image="./images/A2Indiana.png"
        CustomBodyClass="recentWorkBody"
        cardcolor=" bor-48 my-md-4 m-0  mt-4 mb-1  RecentContainer"
        Description="Technologies Used : Laravel, Vue.js, MySql, Bootstrap"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0 bg77BFDD33 py-3"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-6"
        RedirectUrl="https://www.addresstwo.com/"
        redirectNumber="/portfolio/custom-crm-development-for-address-two"
        DevelopmentRecentWorkBtn={true}
        CardMargin="col-12 recentBox"
      />
      <DevelopmentRecentWork
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader Indiana"
        DescriptionHeader="Berger's Table Pads - Custom Made Table Pades Online"
        Image="./images/TablePadsWork.webp"
        CustomBodyClass="recentWorkBody"
        cardcolor=" bor-48 my-md-4 m-0  mt-4 mb-1  RecentContainer"
        Description="Technologies Used : React.js, Asp.net core, Sql server, Authorize.net"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0 bg66575333 py-3"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-6"
        RedirectUrl="https://tablepads.com/"
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-12 recentBox"
      />
      <DevelopmentRecentWork
        CustomImageClass="IndianaImgHeight"
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader Indiana"
        DescriptionHeader="MarketWagon - Local Food
            Delivered"
        Image="./images/MarketWagonindiana.png"
        CustomBodyClass="recentWorkBody"
        cardcolor=" bor-48 my-md-4 m-0  mt-4 mb-1  RecentContainer"
        Description="Technologies Used : Laravel, Vue.js, Alpine.js, MySql"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0 bg55A67E33 py-3"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-6"
        RedirectUrl="https://marketwagon.com/"
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-12 recentBox"
      />
      
      <DevelopmentRecentWork
        CustomImageClass="IndianaImgHeight"
        ImagePosition="left"
        CustomHeaderClass="recentWorkHeader Indiana"
        DescriptionHeader="PanelHive (Event Management System)"
        Image="./images/PanelHiveIndiana.png"
        CustomBodyClass="recentWorkBody"
        cardcolor=" bor-48 my-md-4 m-0  mt-4 mb-1  RecentContainer"
        Description="Technologies Used : Laravel, Vue.js, Alpine.js, MySql"
        customDescriptionItemFirst="col-lg-6 col-md-6 col-12 ps-0 bgFD7A7C33 py-3"
        customDescriptionItemSecond="col-lg-6 col-md-6 col-12 m-auto px-6"
        RedirectUrl="http://panelhive.com/"
        DevelopmentRecentWorkBtn={false}
        CardMargin="col-12 recentBox"
      />
    </>
  );
  var serviceConent = [];
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 pt-4 pb-2 ourKeyCardSize "
      Image="./images/WdIconIndiana1.svg"
      CustomHeaderClass="locationHeader clr0E2B3D align-self-center text-m-center"
      Header="Customized Website Development"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="Web design services in SF customized to your brand are what we specialize in as a custom web development service provider in San Francisco. Our experienced developers carefully design and develop websites that align with your unique business goals and brand identity."
      Division={true}
      DivisionCustomClass='mb-3'
      height={windowWidth <= 991 ? '1px' : '100%'}
      width={windowWidth <= 991 ? '100%' : '1px'}
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize "
      Image="./images/WdIconIndiana2.svg"
      CustomHeaderClass="locationHeader clr0E2B3D align-self-center TpPlaceFont"
      Header="Web App Development"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body={<span className="p-0">Revolutionize your business with our <span onClick={() => navigate("/web-application-development")} className="text-decoration-none IndianaTpPlaceFont cursor-pointer p-0">custom web application development services</span> in San Francisco. We create innovative CRMs, inventory systems, and dashboards that streamline operations, boost efficiency, and drive growth. Our solutions are designed to be secure, scalable, and user-friendly, ensuring a seamless user experience.</span>}
      Division={true}
      DivisionCustomClass='mb-3'
      height={windowWidth <= 991 ? '1px' : '100%'}
      width={windowWidth <= 991 ? '100%' : '1px'}
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 py-md-4 py-2 ourKeyCardSize"
      Image="./images/WdIconIndiana3.svg"
      CustomHeaderClass="locationHeader clr0E2B3D align-self-center TpPlaceFont"
      Header="Ecommerce Web Development New York"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="We develop ecommerce websites according to your unique requirements with the help of our skilled team of developers. Our team can tailor a solution to fit your ecommerce business model regardless of the type of functionality you require."
    />
  );

  serviceConent.push(
    <>
    <span style={{width: windowWidth <= 991 ? '0px': '100%', height: windowWidth <= 991 ? '0' : '1px', backgroundColor: '#c4c4c4'}}/>
    <BreadCrumbCard
      ColSize="col-md-4 pt-md-4 py-2 ourKeyCardSize "
      Image="./images/WdIconIndiana4.svg"
      CustomHeaderClass="locationHeader clr0E2B3D align-self-center TpPlaceFont"
      Header="Web API Development"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="It is essential for businesses to develop web APIs effectively. It will help to set up the flow of information based on the necessity of presenting it. We believe a well-designed API can extend the functionality of the web browser and server."
      Division={true}
      DivisionCustomClass='mb-3'
      height={windowWidth <= 991 ? '1px' : '100%'}
      width={windowWidth <= 991 ? '100%' : '1px'}
    />
    </>
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 pt-md-4 py-2 ourKeyCardSize"
      Image="./images/WdIconIndiana5.svg"
      CustomHeaderClass="locationHeader clr0E2B3D align-self-center text-m-center TpPlaceFont"
      Header="Content Management Systems"
      CustomBodyClass="serviceBodyFonts addMargin ourKeyCardtext"
      Body="Every section can't have specific coding. To solve this issue, we are using a content management service like Shopify, WordPress, etc., which can support us in building a hassle-free website. It is ideal for small-scale websites and online businesses."
      Division={true}
      DivisionCustomClass='mb-3'
      height={windowWidth <= 991 ? '1px' : '100%'}
      width={windowWidth <= 991 ? '100%' : '1px'}
    />
  );
  serviceConent.push(
    <BreadCrumbCard
      ColSize="col-md-4 pt-md-4 py-2 ourKeyCardSize"
      Image="./images/WdIconIndiana6.svg"
      CustomHeaderClass="locationHeader clr0E2B3D align-self-center TpPlaceFont"
      Header="CRM & ERP Development"
      CustomBodyClass="serviceBodyFonts ourKeyCardtext"
      Body="An effective CRM and ERP system is crucial for a seamless online business flow. We prioritize engagement with customers and team members through organized business practices. Our tailored ERP systems are easily accessible for your company."
    />
  );
  const [countNumber, setCountNumber] = useState({
    Count1: 0,
    Count2: 0,
    Count3: 0,
    Count4: 0,
  });
  useEffect(() => {
    window.addEventListener("scroll", CounterFun);
    return () => {
      window.removeEventListener("scroll", CounterFun);
    };
  }, []);
  const CounterFun = () => {
    const scrollTop = window.scrollY;

    if (scrollTop >= 0) {
      setCountNumber({
        ...countNumber,
        Count1: 100,
        Count2: 50,
        Count3: 20,
        Count4: 8,
      });
    }
  };
  const [country, setCountry] = useState(sessionStorage.getItem("Country"));
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [successMsg, setSuccessMsg] = useState({
    reCaptchaErrMsg: "",
    mailSentMsg: false,
  });
  const ReCAPTCHAValue = useRef();
  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const CUSubmit = (e) => {
    e.preventDefault();
    setSuccessMsg({ ...successMsg, reCaptchaErrMsg: "" });
    if (ReCAPTCHAValue.current.getValue() == "") {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please complete the captcha",
      });
    } else if ($("#ContactNumber").val().length < 8) {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please Enter Phone Number",
      });
    } else {
      var formdata = new FormData();
      formdata.append("Name", state.Name);
      formdata.append("ContactNumber", $("#ContactNumber").val());
      formdata.append("Email", state.Email);
      formdata.append("ProjectDetail", state.ProjectDetail);
      formdata.append("ProjectBrief", state.ProjectBrief);
      formdata.append("GoogleCaptcha", ReCAPTCHAValue.current.getValue());
      formdata.append("Subject", "From Contact Us Page");
      formdata.append("PageName", "Indiana Page");
      var requestOptions = {
        method: "POST",
        body: formdata,
      };
      fetch("https://app.addresstwo.com/SprigstackEmail/Index", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var results = JSON.parse(result);
          if (results.Status == "Success" && results.Message == "Success")
            setSuccessMsg({ ...successMsg, mailSentMsg: true });
          else if (
            results.Status == "Failed" &&
            results.Message == "Please Validate Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Please Validate Captcha",
            });
          else if (
            results.Status == "Failed" &&
            results.Message == "Error in Verify Google Captcha"
          )
            setSuccessMsg({
              ...successMsg,
              reCaptchaErrMsg: "Error in Verify Google Captcha",
            });
        })
        .catch((error) => error.throw(error));
    }
  };
  return (
    <React.Fragment>
      <HelmetMetaTag
        titleName="Custom Web development Company in San Francisco | SprigStack"
        desc="Looking for custom web development services in San Francisco? SprigStack offers expert solutions for responsive, user-friendly & customized websites."
        canonical={window?.location?.href}
        ogtitle="Custom Web development Company in San Francisco | SprigStack"
        ogdesc="Looking for custom web development services in San Francisco? SprigStack offers expert solutions for responsive, user-friendly & customized websites."
        ogtype="website"
        ogurl={window?.location?.href}
      />
      <div className="row px-6 pb-md-5 pb-0 bg-262626 LocationIndianaHeroBg">
        <div className="col-12 col-md-6 mt-5 mt-md-0 d-flex justify-content-center align-items-center  flex-column IndianaHeader">
          <h1>Web Development Company San Francisco</h1>
          <p className=" mt-3 p-0 m-0 mb-3">
            We are a <span onClick={() => navigate('/web-development')} className="text-decoration-none IndianaTpPlaceFont cursor-pointer p-0">custom web development company</span> in San Francisco, offering
            comprehensive web application and website development services. With
            the latest technologies, we design stunning websites that are
            visually appealing, highly functional, and easy to use.
          </p>
          <p className="mb-0">
            Our team of skilled San Francisco web developers collaborates
            closely with you to deliver web development services in San
            Francisco according to your unique requirements to help businesses
            succeed. With a deep understanding of San Francisco’s market and a
            nationwide perspective, we deliver websites that drive results.
          </p>
          <br />
          <div className="pb-md-5" style={{ width: "100%" }}>
            {" "}
            <a
              href="https://clutch.co/profile/sprigstack"
              rel="nofollow"
              target="_blank"
            >
              <img
                src="/images/clutch.svg"
                className="img-fluid pe-4  crsr-pointer"
                alt="Clutch icon"
                title="Clutch SprigStack Profile"
              />
            </a>
            <a
              href="https://www.designrush.com/agency/profile/sprigstack"
              rel="nofollow"
              target="_blank"
            >
              <img
                src="/images/sprigstack_designrush.svg"
                className="img-fluid  pe-4  crsr-pointer mt-1"
                alt="Designrush icon"
                title="Designrush SprigStack Profile"
              />
            </a>
          </div>
        </div>
        <div className="col-12 col-md-6  my-5 mx-0  d-md-flex d-block justify-content-center align-items-center">
          {!successMsg.mailSentMsg ? (
            <div
              style={{
                paddingLeft: windowWidth <= 991 ? "" : "160px",
                paddingRight: windowWidth <= 991 ? "" : "80px",
              }}
            >
              <form className="IndianaForm bg-white" onSubmit={CUSubmit}>
                <div className="row px-3 pb-3">
                  <div className="col-12 my-3 IndianaQuote">
                    Get Free Consultation
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Full Name*"
                      onChange={handleChange}
                      name="Name"
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email ID*"
                      onChange={handleChange}
                      name="Email"
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <PhoneInput
                      country={country}
                      autoFormat="true"
                      inputProps={{
                        name: "ContactNumber",
                        required: true,
                        id: "ContactNumber",
                        className: " form-control",
                      }}
                      placeholder="Phone Number*"
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <select
                      id="ProjectDetail"
                      name="ProjectDetail"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option defaultChecked value="Request a consultation">
                        Request a consultation
                      </option>
                      <option value="Hire Developers">Hire Developers</option>
                      <option value="Website Development">
                        Website Development
                      </option>
                      <option value="App Development">App Development</option>
                      <option value="API Integration Services">
                        API Integration Services
                      </option>
                      <option value="UI/UX project">UI/UX project</option>
                      <option value="SEO services">SEO services</option>
                      <option value="DevOps">DevOps</option>
                      <option value="Project Management">
                        Project Management
                      </option>
                      <option value="Project Estimation">
                        Project Estimation
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-12 mb-3">
                    <textarea
                      className="form-control"
                      placeholder="How can we help you?"
                      onChange={handleChange}
                      name="ProjectBrief"
                      rows="4"
                    />
                  </div>
                  <div className="col-12 justify-content-center d-flex ">
                    <ReCAPTCHA
                      ref={ReCAPTCHAValue}
                      sitekey={process.env.REACT_APP_EMAIL_RECAPTCHA_SITE_KEY}
                    />
                  </div>
                  <div className="col-12 justify-content-center d-flex">
                    <span className="text-danger my-2">
                      {successMsg.reCaptchaErrMsg}
                    </span>
                  </div>
                  <div className="col-12 text-center mb-md-0 mb-3">
                    <button
                      className="btn btn-primary phpModalBtn"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <>
              <form
                style={{ height: windowWidth <= 991 ? "" : "400px" }}
                className="IndianaForm bg-white d-flex justify-content-center align-items-center"
              >
                <div
                  className="text-center h3 bg-white mx-5 py-5"
                  style={{ fontSize: windowWidth <= 991 ? "17px" : "24px" }}
                >
                  Thank you for reaching out to us. We will get back to you
                  shortly!
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      {windowWidth <= 991 ? (
        <div className="row px-6 py-5 pb-4">
          <div className="col-6  text-center ">
            <div className="ProjectsCount pt-2">
              <div className="col-12 CountProject ">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count1}
                  duration={parseInt(2)}
                />
                +
              </div>
              <div className="col-12 ProjectTitle">Projects Completed</div>
            </div>
          </div>
          <div className="col-6 text-center d-flex ">
            <div className="ProjectsCount pt-2">
              <div className="col-12 CountProject ">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count2}
                  duration={parseInt(2)}
                />
                +
              </div>
              <div className="col-12 ProjectTitle">Happy Clients</div>
            </div>
          </div>
          <div className="col-6 my-4  text-center d-flex ">
            <div className="ProjectsCount pt-2">
              <div className="col-12 CountProject ">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count3}
                  duration={parseInt(2)}
                />
                +
              </div>
              <div className="col-12 ProjectTitle">Experts in Team</div>
            </div>
          </div>
          <div className="col-6 my-4  text-center d-flex ">
            <div className="ProjectsCount pt-2">
              <div className="col-12 CountProject ">
                <CountSetInterval
                  label={"count"}
                  number={countNumber.Count4}
                  duration={parseInt(2)}
                />
                +
              </div>
              <div className="col-12 ProjectTitle">Years of Trust</div>
            </div>
          </div>
        </div>
      ) : (
        <div className=" position-relative">
          <div className="row px-6 width100 position-absolute  top-60">
            <div className=" d-flex align-items-center ">
              <div className="col-3  text-center d-flex justify-content-center">
                <div className="ProjectsCount pt-2">
                  <div className="col-12 CountProject">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count1}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12 ProjectTitle">Projects Completed</div>
                </div>
              </div>
              <div className="col-3  text-center d-flex justify-content-center">
                <div className="ProjectsCount pt-2">
                  <div className="col-12 CountProject">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count2}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12 ProjectTitle">Happy Clients</div>
                </div>
              </div>
              <div className="col-3  text-center d-flex justify-content-center">
                <div className="ProjectsCount pt-2">
                  <div className="col-12 CountProject">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count3}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12 ProjectTitle">Experts in Team</div>
                </div>
              </div>
              <div className="col-3  text-center d-flex justify-content-center">
                <div className="ProjectsCount pt-2">
                  <div className="col-12 CountProject">
                    <CountSetInterval
                      label={"count"}
                      number={countNumber.Count4}
                      duration={parseInt(2)}
                    />
                    +
                  </div>
                  <div className="col-12 ProjectTitle">Years of Trust</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="position-absolute"><HeroSectionProjects /></div> */}
        </div>
      )}

      <div className="row px-6">
        {windowWidth <= 991 ? (
          <HeaderWithDynamicContent
            pt="true"
            flag={true}
            HrLine="true"
            BgColor=""
            Header="Our Key Web Development <br/> Services in San Francisco"
            ContainerBottomPadding="pb-md-3 pt-md-5 pt-0"
            HeaderPadding="ourKeyHeading"
            Content={[serviceConent]}
          />
        ) : (
          <HeaderWithDynamicContent
            HrLine="true"
            BgColor=""
            Header="Our Key Web Development <br/> Services in San Francisco"
            ContainerBottomPadding="pb-md-3 pt-md-5 pt-0"
            HeaderPadding="ourKeyHeading"
            Content={[serviceConent]}
          />
        )}
      </div>
      <div className="row px-6">
        <HeaderWithDynamicContent
          pt={windowWidth <= 911 ? "true" : ""}
          HrLine="true"
          BgColor=""
          Header="Major Benefits Of Web Development"
          ContainerBottomPadding="pb-md-3 pt-0"
          HeaderPadding=""
          flag={true}
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/webAppIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Quick Turn Around"
          CustomClass="MajorBenefitsContainer"
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/crossPlatformIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Mobile Friendly"
          CustomClass="MajorBenefitsContainer"
          alt="Cross-Platform Icon"
          title="Cross-Platform "
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/manageIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Easy to Use and <br/> Manage"
          CustomClass="MajorBenefitsContainer"
          alt="Manage Icon"
          title="Manage "
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/deployIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Scalable & <br/> Quickly Deployed"
          CustomClass="MajorBenefitsContainer"
          alt="Deploy Icon"
          title="Deploy"
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/secureIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Data Security"
          CustomClass="MajorBenefitsContainer"
          alt="Secure Icon"
          title="Secure"
        />
        <NamePlateCard
          ColSize="col-md-4 col-6 py-md-4 py-2 NamePlateSize"
          Image="./images/redcuedCostIcon.svg"
          CustomHeaderClass="AU_Font5 NamePlateCardHeading"
          Header="Budget Friendly"
          CustomClass="MajorBenefitsContainer"
          alt="Reduced Cost Icon"
          title="Reduced Cost"
        />
      </div>
      <div className="row px-6 bgF7F7F7">
        <HeaderWithDynamicContent
          HrLine="true"
          BgColor=""
          Header="Web Development Process That We Follow"
          ContainerBottomPadding="pb-md-3 pt-0"
          HeaderPadding=""
          flag={true}
        />
      </div>
      <div className="row px-6 bgF7F7F7 pb-4">
        <>
          <div className="col-md-3 col-12 ">
            <div className="row m-2 processDynamicDiv">
              <div className="col-12 text-center1">
                <div className="processCircle mt-4">
                  <img
                    className="img-fluid "
                    src="/images/processWebIcon1.svg"
                    alt="Web Process Icon 1"
                    title="Web Process 1 - SprigStack"
                  />
                </div>
                <h3
                  className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                >
                  1. Requirement Gathering
                </h3>
              </div>
              <div className="col-12 pb-0 mb-0">
                <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                  The first step for building an impactful website or
                  application is putting a well-defined requirement together.
                  That is the first and key step we follow.
                </div>
              </div>
            </div>
            <div className="row m-2 position-relative">
              <div className="col-12 processCenter"></div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-4 mt-md-0">
            <div className="row m-2 processDynamicDiv">
              <div className="col-12 text-center1">
                <div className="processCircle mt-4">
                  <img
                    className="img-fluid "
                    src="/images/processWebIcon2.svg"
                    alt="Web Process Icon 2"
                    title="Web Process 2 - SprigStack"
                  />
                </div>
                <h3
                  className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                >
                  2. Defining Technology
                </h3>
              </div>
              <div className="col-12 pb-0 mb-0">
                <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                  Websites can be built with numerous different technologies. We
                  choose the best suitable according to the needs and
                  capabilities of the website we are building.
                </div>
              </div>
            </div>
            <div className="row m-2 position-relative">
              <div className="col-12 processCenter"></div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-4 mt-md-0">
            <div className="row m-2 processDynamicDiv">
              <div className="col-12 text-center1">
                <div className="processCircle mt-4">
                  <img
                    className="img-fluid "
                    src="/images/processWebIcon3.svg"
                    alt="Web Process Icon 3"
                    title="Web Process 3 - SprigStack"
                  />
                </div>
                <h3
                  className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                >
                  3. UI/UX Design
                </h3>
              </div>
              <div className="col-12 pb-0 mb-0">
                <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                  Look & feel are very important for websites to attract more
                  and more users. Overall, experience and user-friendliness are
                  equally important. Website development begins with this step.
                </div>
              </div>
            </div>
            <div className="row m-2 position-relative">
              <div className="col-12 processCenter"></div>
            </div>
          </div>
          <div className="col-md-3 col-12 mt-4 mt-md-0">
            <div className="row m-2 processDynamicDiv">
              <div className="col-12 text-center1">
                <div className="processCircle mt-4">
                  <img
                    className="img-fluid "
                    src="/images/processWebIcon4.svg"
                    alt="Web Process Icon 4"
                    title="Web Process 4 - SprigStack"
                  />
                </div>
                <h3
                  className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                >
                  4. Prototype
                </h3>
              </div>
              <div className="col-12 pb-0 mb-0">
                <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                  Based on the theme and look we finalized in the previous step,
                  we provide a working prototype to clients to let them
                  understand the result.
                </div>
              </div>
            </div>
            <div className="row m-2 position-relative">
              <div className="col-12 processCenter"></div>
            </div>
          </div>
          <div className="row d-md-flex d-block justify-content-center p-0 m-0">
            <div className="col-md-3 col-12 mt-4 my-md-4 my-0">
              <div className="row m-2 processDynamicDiv">
                <div className="col-12 text-center1">
                  <div className="processCircle mt-4">
                    <img
                      className="img-fluid "
                      src="/images/processWebIcon5.svg"
                      alt="Web Process Icon 5"
                      title="Web Process 5 - SprigStack"
                    />
                  </div>
                  <h3
                    className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                  >
                    5. Development & QA
                  </h3>
                </div>
                <div className="col-12 pb-3 pb-md-0 mb-0">
                  <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                    The development phase starts as soon as requirements are
                    clear and some designs are finalized. Experts must do this
                    since it is the heart of any web application.
                  </div>
                </div>
              </div>
              <div className="row m-2 position-relative">
                <div className="col-12 processCenter"></div>
              </div>
            </div>
            <div className="col-md-3 col-12 mt-4 my-md-4 my-0">
              <div className="row m-2 processDynamicDiv">
                <div className="col-12 text-center1">
                  <div className="processCircle mt-4">
                    <img
                      className="img-fluid "
                      src="/images/processWebIcon6.svg"
                      alt="Web Process Icon 6"
                      title="Web Process 6 - SprigStack"
                    />
                  </div>
                  <h3
                    className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                  >
                    6. Deployment
                  </h3>
                </div>
                <div className="col-12 pb-3 pb-md-0 mb-0">
                  <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                    When the development & QA process is complete and the
                    website is ready to be moved to the production server, that
                    phase is called Deployment. We take care of it and make sure
                    the transition is smooth.
                  </div>
                </div>
              </div>
              <div className="row m-2 position-relative">
                <div className="col-12 processCenter"></div>
              </div>
            </div>
            <div className="col-md-3 col-12 mt-4 my-md-4 my-0">
              <div className="row m-2 processDynamicDiv">
                <div className="col-12 text-center1">
                  <div className="processCircle mt-4">
                    <img
                      className="img-fluid "
                      src="/images/processWebIcon7.svg"
                      alt="Web Process Icon 7"
                      title="Web Process 7 - SprigStack"
                    />
                  </div>
                  <h3
                    className={` pt-lg-4 pt-3 px-lg-4  processWeFollowHeaderFont text-center clr0E2B3D`}
                  >
                    7. Support & Maintenance
                  </h3>
                </div>
                <div className="col-12 pb-3 pb-md-0 mb-0">
                  <div className={` mt-2 px-lg-4  processWeFollowFonts `}>
                    After successful deployment on the production server, we
                    ensure everything works as expected and maintain them as
                    long as clients want us to. We have a 100% maintenance
                    retention ratio for our work.
                  </div>
                </div>
              </div>
              <div className="row m-2 position-relative">
                <div className="col-12 processCenter"></div>
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="row ">
        <HeaderWithDynamicContent
          HrLine="true"
          BgColor=""
          Header="Technologies Used for Websites & Web Applications"
          ContainerBottomPadding="pb-md-3 pt-0"
          HeaderPadding=""
          flag={true}
        />
        <div className="col-12">
          {windowWidth <= 911 ? (
            <>
              <div className="row py-4">
                <div
                  className="col-12 IndianaTable"
                  // style={{
                  //   borderBottom: "1px solid #e7e7e7",
                  // }}
                >
                  <b>Front-End</b>
                </div>
                <div
                  className="col-12"
                  style={{
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <div className="row py-4 px-5 d-flex align-items-center">
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo1.png"
                        alt="React logo"
                        title="Frontend Technology React "
                      />
                      <h4 className="mt-3 techNameClass">React.js</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo2.png"
                        alt="Vue.js logo"
                        title="Frontend Technology Vue.js "
                      />
                      <h4 className="mt-3 techNameClass">Vue.js</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo3.png"
                        alt="Next.js logo"
                        title="Frontend Technology Next.js"
                      />
                      <h4 className="mt-3 techNameClass">Next.js</h4>
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo4.png"
                        alt="Angular logo"
                        title="Frontend Technology Angular"
                      />
                      <h4 className="mt-3 techNameClass">Angular</h4>
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo5.png"
                        alt="Html  logo"
                        title="Frontend Technology Html"
                      />
                      <h4 className="mt-3 techNameClass">Html</h4>
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo6.png"
                        alt="CSS logo"
                        title="Frontend Technology CSS"
                      />
                      <h4 className="mt-3 techNameClass">Css</h4>
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo7.png"
                        alt="BootStrap logo"
                        title="Frontend Technology BootStrap"
                      />
                      <h4 className="mt-3 techNameClass">Bootstrap</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-2">
                <div
                  className="col-12 IndianaTable"
                  // style={{
                  //   borderBottom: "1px solid #e7e7e7",
                  // }}
                >
                  <b>Back-End</b>
                </div>
                <div
                  className="col-12"
                  style={{
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <div className="row py-4 px-5 d-flex align-items-center">
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo1.png"
                        alt="Asp.net core logo"
                        title="Backend Technology Asp.net core "
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo2.png"
                        alt="Asp.net MVC logo"
                        title="Backend Technology Asp.net MVC "
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo3.png"
                        alt="Node.js logo"
                        title="Backend Technology Node.js"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Asp.net Core</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Asp.net MVC</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Node.js</h4>
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo4.png"
                        alt="Php logo"
                        title="Backend Technology Php"
                      />
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo5.png"
                        alt="Laravel logo"
                        title="Backend Technology Laravel"
                      />
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo6.png"
                        alt="Code Igniter logo"
                        title="Backend Technology Code Igniter"
                      />
                    </div>
                    <div className="col-4 mb-1  p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Php</h4>
                    </div>
                    <div className="col-4 mb-1  p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Laravel</h4>
                    </div>
                    <div className="col-4 mb-1  p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Code Igniter</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-2">
                <div
                  className="col-12 IndianaTable"
                  // style={{
                  //   borderBottom: "1px solid #e7e7e7",
                  // }}
                >
                  <b>CMS</b>
                </div>
                <div
                  className="col-12"
                  style={{
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <div className="row py-4 px-5 d-flex align-items-center">
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms1.png"
                        alt="WooCommerce logo"
                        title="CMS WooCommerce"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms2.png"
                        alt="Shopify logo"
                        title="CMS Shopify"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms3.png"
                        alt="Joomla logo"
                        title="CMS Joomla"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">WooCommerce</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Shopify</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Joomla</h4>
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms4.png"
                        alt="Wix logo"
                        title="CMS Wix"
                      />
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms5.png"
                        alt="Webflow logo"
                        title="CMS Webflow"
                      />
                    </div>
                    <div className="col-4 mb-1  p-0 m-0 text-center"></div>
                    <div className="col-4 mb-1  p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Wix</h4>
                    </div>
                    <div className="col-4 mb-1  p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Webflow</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-2">
                <div
                  className="col-12 IndianaTable"
                  // style={{
                  //   borderBottom: "1px solid #e7e7e7",
                  // }}
                >
                  <b>Databases</b>
                </div>
                <div
                  className="col-12"
                  style={{
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <div className="row py-4 px-5 d-flex align-items-center">
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database1.png"
                        alt="Sql Server logo"
                        title="Data Base Sql Server"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database2.png"
                        alt="MySql Server logo"
                        title=" Data Base MySql Server"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database3.png"
                        alt="MongoDB logo"
                        title="Data Base MongoDB"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Sql Server</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">MySql Server</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">MongoDB</h4>
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database4.png"
                        alt="PostgreSql logo"
                        title="Data Base PostgreSql"
                      />
                    </div>
                    <div className="col-4 mb-1 mt-4 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database5.png"
                        alt="AWS RDS logo"
                        title="Data Base AWS RDS"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center"></div>
                    <div className="col-4 mb-1  p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">PostgreSql</h4>
                    </div>
                    <div className="col-4 mb-1  p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">AWS RDS</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-2">
                <div
                  className="col-12 IndianaTable"
                  // style={{
                  //   borderBottom: "1px solid #e7e7e7",
                  // }}
                >
                  <b>Cloud Services</b>
                </div>
                <div
                  className="col-12"
                  // style={{
                  //   borderBottom: "1px solid #e7e7e7",
                  // }}
                >
                  <div className="row py-4 px-5 d-flex align-items-center">
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/CloudService1.png"
                        alt="Amazon AWS logo"
                        title="Cloud Amazon AWS"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/CloudService2.png"
                        alt="Microsoft Azure logo"
                        title="Cloud Microsoft Azure"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <img
                        className="img-fluid"
                        src="/images/Technology/CloudService3.png"
                        alt="Google  Drive logo"
                        title="Cloud Service"
                      />
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Amazon AWS</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Microsoft Azure</h4>
                    </div>
                    <div className="col-4 mb-1 p-0 m-0 text-center">
                      <h4 className="mt-3 techNameClass">Google Cloud</h4>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div
                  className="col-2 IndianaTable"
                  style={{
                    borderRight: "1px solid #e7e7e7",
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <b>Front-End</b>
                </div>
                <div
                  className="col-10 pt-4"
                  style={{
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <div className="row  px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo1.png"
                        alt="React logo"
                        title="Frontend Technology React "
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo2.png"
                        alt="Vue.js logo"
                        title="Frontend Technology Vue.js "
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo3.png"
                        alt="Next.js logo"
                        title="Frontend Technology Next.js"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo4.png"
                        alt="Angular logo"
                        title="Frontend Technology Angular"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo5.png"
                        alt="Html  logo"
                        title="Frontend Technology Html"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo6.png"
                        alt="CSS logo"
                        title="Frontend Technology CSS"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/frontendlogo7.png"
                        alt="BootStrap logo"
                        title="Frontend Technology BootStrap"
                      />
                    </div>
                  </div>
                  <div className="row  px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">React.js</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Vue.js</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Next.js</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Angular</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Html</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Css</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Bootstrap</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-2 IndianaTable"
                  style={{
                    borderRight: "1px solid #e7e7e7",
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <b>Back-End</b>
                </div>
                <div
                  className="col-10 pt-4"
                  style={{
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <div className="row px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo1.png"
                        alt="Asp.net core logo"
                        title="Backend Technology Asp.net core "
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo2.png"
                        alt="Asp.net MVC logo"
                        title="Backend Technology Asp.net MVC "
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo3.png"
                        alt="Node.js logo"
                        title="Backend Technology Node.js"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo4.png"
                        alt="Php logo"
                        title="Backend Technology Php"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo5.png"
                        alt="Laravel logo"
                        title="Backend Technology Laravel"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/backendlogo6.png"
                        alt="Code Igniter logo"
                        title="Backend Technology Code Igniter"
                      />
                    </div>
                  </div>
                  <div className="row  px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Asp.net Core</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Asp.net MVC</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Node.js</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Php</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Laravel</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Code Igniter</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-2 IndianaTable"
                  style={{
                    borderRight: "1px solid #e7e7e7",
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <b>CMS</b>
                </div>
                <div
                  className="col-10 pt-4"
                  style={{
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <div className="row  px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms1.png"
                        alt="WooCommerce logo"
                        title="CMS WooCommerce"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms2.png"
                        alt="Shopify logo"
                        title="CMS Shopify"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms3.png"
                        alt="Joomla logo"
                        title="CMS Joomla"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms4.png"
                        alt="Wix logo"
                        title="CMS Wix"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/cms5.png"
                        alt="Webflow logo"
                        title="CMS Webflow"
                      />
                    </div>
                  </div>
                  <div className="row  px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3 p-0">
                      <h4 className="mt-3 p-0 techNameClass">WooCommerce</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Shopify</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Joomla</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Wix</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Webflow</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-2 IndianaTable"
                  style={{
                    borderRight: "1px solid #e7e7e7",
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <b>Databases</b>
                </div>
                <div
                  className="col-10 pt-4"
                  style={{
                    borderBottom: "1px solid #e7e7e7",
                  }}
                >
                  <div className="row  px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database1.png"
                        alt="Sql Server logo"
                        title="Data Base Sql Server"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database2.png"
                        alt="MySql Server logo"
                        title=" Data Base MySql Server"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database3.png"
                        alt="MongoDB logo"
                        title="Data Base MongoDB"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database4.png"
                        alt="PostgreSql logo"
                        title="Data Base PostgreSql"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/database5.png"
                        alt="AWS RDS logo"
                        title="Data Base AWS RDS"
                      />
                    </div>
                  </div>
                  <div className="row  px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Sql Server</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">MySql Server</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">MongoDB</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">PostgreSql</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">AWS RDS</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-2 IndianaTable"
                  style={{
                    borderRight: "1px solid #e7e7e7",
                  }}
                >
                  <b>Cloud Services</b>
                </div>
                <div className="col-10 pt-4">
                  <div className="row  px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/CloudService1.png"
                        alt="Amazon AWS logo"
                        title="Cloud Amazon AWS"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/CloudService2.png"
                        alt="Microsoft Azure logo"
                        title="Cloud Microsoft Azure"
                      />
                    </div>
                    <div className="col-1 text-center me-3">
                      <img
                        className="img-fluid"
                        src="/images/Technology/CloudService3.png"
                        alt="Google Drive logo"
                        title="Cloud Google Drive"
                      />
                    </div>
                  </div>
                  <div className="row  px-5 d-flex align-items-center">
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Amazon AWS</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Microsoft Azure</h4>
                    </div>
                    <div className="col-1 text-center me-3">
                      <h4 className="mt-3 techNameClass">Google Cloud</h4>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="IndianBgCta my-3 ">
        <p>
          Transform your business with our <br />
          custom mobile app development
          <br /> services
        </p>
        <button
          className="btn-mpcheader mb-5"
          style={{ background: "#FFFFFF", color: "#EE720B" }}
          onClick={() => {
            setShow(true);
          }}
          >
          Get a free consulation{" "}
          <img src="/images/personalizedArrow.svg" alt="" />
        </button>
      </div>
      <HeaderWithDynamicContent
        pt={windowWidth <= 911 ? "true" : ""}
        BgColor=""
        Header="Recent Web Development Work"
        HeaderPadding="RecentWorkHeading"
        ContentCustomClass=""
        ContainerBottomPadding="RecentworkPadding"
        flag={true}
        Content={[recentWorkContent]}
      />
      <NewClientComp dataSets={dataSets} />
      <HeaderWithDynamicContent
        pt={windowWidth <= 911 ? "true" : ""}
        BgColor=""
        Header="Why Choose Us for Website Development in  San Francisco City?"
        HeaderPadding="RecentWorkHeading"
        ContentCustomClass=""
        ContainerBottomPadding="RecentworkPadding"
        flag={true}
      />
      <div className="row px-6 my-5 pb-md-5 pb-0">
        <div className="col-md-4 col-12 ">
          <div className="row IndianaWhyChoose px-3 m-auto">
            <div className="col-12 my-4">
              <img
                src="/images/web-devChooseUsIcon1.svg"
                alt="Widely Experienced Team"
                title="Widely Experienced Team"
              />
            </div>
            <div className="col-12 WhyChooseIndiana ">
              Widely Experienced Team
              <p className="my-4 mb-5">
                Have your website built with our expert web development team and
                be assured for the great result. Experience is the key here.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mt-4 mt-md-0">
          <div className="row IndianaWhyChoose px-3 m-auto">
            <div className="col-12 my-4">
              <img
                src="/images/web-devChooseUsIcon2.svg"
                alt="Top Notch Security"
                title="Top Notch Security"
              />
            </div>
            <div className="col-12 WhyChooseIndiana ">
              Top Notch Security
              <p className="my-4 mb-5">
                When you work with us, your idea and data are secured intact. We
                do NDA with all of our clients and share the same process with
                our employees.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mt-4 mt-md-0">
          <div className="row IndianaWhyChoose px-3 m-auto">
            <div className="col-12 my-4">
              <img
                src="/images/web-devChooseUsIcon3.svg"
                alt="100% Assured Quality Output"
                title="100% Assured Quality Output"
              />
            </div>
            <div className="col-12 WhyChooseIndiana ">
              100% Assured Quality Output
              <p className="my-4 mb-5">
                We are known for our quality output and support. No client is
                big or small for us, all of them get 100% assurance in terms of
                quality and result.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Popups show={show} setShow={setShow} title="FREE CONSULTATION" size="lg">
        <PersonalizedActionModal />
      </Popups>
    </React.Fragment>
  );
}
